import English from '@/utillities/languages/English'

export default{
    ...English,
    "create_tod_billing_widget": "创建TOD计费小部件",
    "edit_tod_billing_widget": "编辑TOD计费小部件",
    "choose_from_machine_groups": "从机器组中选择",
    "tod_billing": "TOD计费",
    "choose": "选择",

    "create_shift_based_machine_usage_log": "创建机器使用日志",
    "shift_based_machine_usage_log": "机器使用日志",
    "rejected": "已拒绝",
    "machine_operator": "机器操作员",
    "production_end_timestamp": "生产结束时间",
    "production_start_timestamp": "生产开始时间",
    "running_time": "运行时间",
    "idle_time": "空闲时间",
    "down_time": "停机时间",

    "date_row_index": "日期行",
    "date_position": "日期位置",

    "safety_incident_sheet": "安全事故表",
    "safety_inspection_sheet": "安全检查表",

    "ghg_emission_logs": "温室气体排放日志",
    "add_ghg_emission_data": "添加温室气体排放日志",
    "ghg_scope_conversion": "温室气体范围转换",
    "create_ghg_scope_conversion": "创建温室气体范围转换",
    "ghg_data_logs": "温室气体排放数据日志",

    "choose_inspection_type_optional": "选择检查类型（可选）",
    
    "supplier_code": "供应商代码",
    "contact": "联系",
    "point_of_contact": "联系人",
    "quantity_supplied": "供应数量",
    "create_product": "创建产品",
    "products": "产品",
    "data": "数据",
    "timezone": "时区",    
    "tariffs": "关税",

    "days_delta": "天数增量",
"machine_groups": "机器组",
"param_groups": "参数组",
"user_groups": "用户组",

    "topic_prefix": "主题前缀",
    "message_direction": "消息方向",

    "safety_inspection": "安全检查",
    "write_inspection_log": "写检查日志",
    "inspection_id": "检查编号",
    "inspector_id": "检查员编号",
    "inspection_date": "检查日期",
    "inspection_type": "检查类型",
    "findings": "发现",
    "actions_required": "需要的措施",
    "followup_date": "跟进日期",
    "status": "状态",

    "safety_incident": "安全事故",
    "write_incident": "写事件",
    "incident_description": "事件描述",
    "severity": "严重程度",
    "accident": "事故",
    "accident_with_loss": "有损失的事故",
    "closed": "关闭",
    "corrective_action": "纠正措施",
    "employee_id": "员工编号",
    "apply": "应用",
    "choose_location_optional": "选择位置（可选）",

    "create_tariff": "创建关税",
    "default_tariff_val": "默认关税值",
    "add_tariff_data": "添加关税数据",
    "edit_tariff": "编辑关税",
    "delete_tariff": "删除关税",
    "tariff_value": "关税值",
    
    "machine_status_timeline":"机器状态时间线",
    "total_duration": "总时长",
    "generate":"生成",
    "remove_machine":"移除机器",
    "edit_machine_status_timeline_widget":"编辑机器状态时间线小部件",
    "add_machine_Status__timeline_widget":"添加机器状态时间轴小部件",
    "select_timeframe":"选择时间范围",
    "timeframe_type_shift":"转移",
    "timeframe_type_custom":"风俗",
    "timeframe_type_yesterday":"昨天",
    "edit_report_details":"编辑报告详细信息",
    "download_current_template":"下载当前模板",
    "date_column_index":"日期列（索引）",
    "select_area":"选择区域",
    "select_shift": "选择班次",
    "dashboard_heading"	:	"仪表盘",
 "production_params"	:	"产品参数",
 "no_modules_configured"	:	"没有配置模块",
 "dashboard_panel"	:	"仪表盘面板",
 "choose_type"	:	"选择类型",
 "name_is_required"	:	"名称是必填项",
 "description_is_required"	:	"描述是必填选项",
 "required"	:	"必填项",
 "you_have_to_select_something"	:	"请进行选择",
 "name_must_be_less_than_30_characters"	:	"名称必须少于30个字符",
 "name_must_be_less_than_25_characters"	:	"名称必须少于25个字符",
 "name_must_be_less_than_50_characters"	:	"名称必须少于50个字符",
 "description_must_be_less_than_35_characters"	:	"描述必须少于35个字符",
 "description_must_be_less_than_55_characters"	:	"描述必须少于55个字符",
 "number_must_be_less_than_6_digits"	:	"数字必须少于6位",
 "number_must_be_less_than_8_digits"	:	"数字必须少于8位",
 "number_must_be_less_than_7_digits"	:	"数字必须少于7位",
 "panel"	:	"面板",
 "gauge"	:	"测量仪表",
 "param"	:	"参数",
 "machine"	:	"机器",
 "add_panel_to_dashboard"	:	"向仪表盘添加面板",
 "edit_dashboard_panel"	:	"编辑仪表盘面板",
 "param_type"	:	"参数类型",
 "workspace"	:	"工作空间",
 "device"	:	"设备",
 "name"	:	"名称",
 "time_duration"	:	"时间范围",
 "submit"	:	"提交",
 "cancel"	:	"取消",
 "close"	:	"关闭",
 "usage"	:	"使用情况",
 "peak_value"	:	"峰值",
 "current_data"	:	"当前数据",
 "parameter"	:	"参数",
 "computed_param"	:	"计算参数",
 "derived_param"	:	"派生参数",
 "last_7_days"	:	"最近7天",
 "last_30_days"	:	"最近30天",
 "last_60_days"	:	"最近60天",
 "last_90_days"	:	"最近90天",
 "last_180_days"	:	"最近180天",
 "last_365_days"	:	"最近365天",
 "last_1_hour"	:	"最近1小时",
 "last_3_hours"	:	"最近3小时",
 "last_6_hours"	:	"最近6小时",
 "reorder_views"	:	"重新排序",
 "create_view"	:	"创建",
 "clear_view_name_filter"	:	"清除筛选",
 "view_type"	:	"视图类型",
 "description"	:	"描述",
 "table_view"	:	"表格视图",
 "widget_view"	:	"组件视图",
 "dynamic_timeframed_widget_view"	:	"动态时间框架组件视图",
 "diagram_view"	:	"图表视图",
 "energy_usage_view"	:	"能源使用视图",
 "machine_scroll_view"	:	"机器滚动视图",
 "param_scroll_view"	:	"参数滚动视图",
 "consolidated_view"	:	"综合视图",
 "air_quality_view"	:	"空气质量视图",
 "view_auto_change"	:	"视图自动更换",
 "no_views_configured"	:	"没有配置视图",
 "add_to_dashboard"	:	"添加到仪表盘",
 "edit_view_name"	:	"编辑视图名称",
 "edit_view"	:	"编辑视",
 "refresh"	:	"刷新",
    "save":"節省",
    "discard": "丟棄",
    "adjust": "調整",
 "create_machine"	:	"创建机器",
 "add_machine"	:	"添加机器",
    "delete_premise_area":"刪除場所區域",
 "sequence"	:	"序列",
 "machine_type"	:	"机器类型",
 "physical_machine"	:	"物理机器",
 "virtual_machine"	:	"虚拟机器",
 "positive_workspace"	:	"正向工作空间",
 "positive_machine"	:	"正向机器",
 "negative_workspace"	:	"反向工作空间",
 "negative_machine"	:	"反向机器",
 "label"	:	"标签",
 "positive_machine_Ids"	:	"正向机器ID",
 "negative_machine_Ids"	:	"反向机器ID",
 "trend_analysis_tools"	:	"趋势分析工具",
 "energy_consumption_by_machines"	:	"机器能源消耗",
 "Create_report"	:	"创建报告",
 "view_report"	:	"查看报告",
 "tarrif_on"	:	"关税开启",
 "active_energy"	:	"有功电能",
 "apparent_energy"	:	"视在电能",
 "automate_report"	:	"自动化报告",
 "add_root_machine"	:	"添加根机器",
 "add_common_machine"	:	"添加普通机器",
 "notification_groups"	:	"通知组",
 "add_machines_to_list"	:	"将机器添加到列表",
 "machine_list"	:	"机器列表",
 "data_historian"	:	"数据历史站",
 "timeframed_trends"	:	"时间框架趋势",
 "dual_axis_trend_comparison"	:	"双轴趋势比较",
 "granular24_hrs_trend"	:	"24小时细粒度趋势",
 "machinewise_trend"	:	"机器趋势",
 "basic_statistical_trends"	:	"基本统计趋势",
 "log_analysis"	:	"日志分析",
 "aqi_comparison_panel"	:	"AQI比较面板",
 "time_of_day_trend"	:	"每日具体时段能耗趋势",
 "param_historical_comparisons"	:	"参数历史比较",
 "energy_usage_trends"	:	"能源使用趋势",
 "machine_name"	:	"机器名称",
 "param_name"	:	"参数名称",
 "trend_view"	:	"趋势视图",
 "raw"	:	"原始数据",
 "trend"	:	"趋势",
 "normalised"	:	"标准化",
 "from_date"	:	"开始日期",
 "to_date"	:	"结束日期",
 "from"	:	"从",
 "to"	:	"至",
 "hour"	:	"小时",
 "minute"	:	"分钟",
 "generate_report"	:	"生成报告",
 "time_range"	:	"时间范围",
 "no_data_available"	:	"没有可用数据",
 "daily"	:	"每日",
 "monthly"	:	"每月",
 "weekly"	:	"每周",
 "week"	:	"每",
 "yearly"	:	"每年",
 "device_name"	:	"设备名称",
 "machine_wise_comparison"	:	"机器比较",
 "stats_detailed_report"	:	"统计详细报告",
 "log_type"	:	"日志类型",
 "choose_Log"	:	"选择日志",
 "area"	:	"区域",
 "aq_device"	:	"AQ设备",
 "nebeskie_labs_office"	:	"Nebeskie实验室办公室",
 "date_range"	:	"日期范围",
 "this_year"	:	"今年",
 "last 6 months"	:	"最近6个月",
 "last quater"	:	"上个季度",
 "mtd"	:	"本月至今",
 "ytd"	:	"本年至今",
 "yesterday"	:	"昨天",
 "custom"	:	"自定义",
 "run_report"	:	"运行报告",
 "create_report"	:	"创建报告",
 "usage_report"	:	"使用报告",
 "consolidated_report"	:	"综合报告",
 "dynamic_excel_report"	:	"动态Excel报告",
 "energy_usage_report"	:	"能源使用报告",
 "create_action"	:	"创建动作",
 "action_type"	:	"操作类型",
 "notification_group"	:	"通知组",
 "address"	:	"地址",
 "number_of_registers"	:	"寄存器数量",
 "value"	:	"数值",
 "send_notification"	:	"发送通知",
 "write_parameter"	:	"写入参数",
 "relays"	:	"继电器",
 "relay_1"	:	"继电器1",
 "relay_2"	:	"继电器2",
 "relay_3"	:	"继电器3",
 "relay_4"	:	"继电器4",
 "email_must_be_valid"	:	"必须是有效的邮箱地址",
 "it_should_be_a_number"	:	"应该是一个数字",
 "it_should_be_a_valid_number"	:	"应该是一个有效的数字",
 "operator"	:	"操作符",
 "operand"	:	"操作数",
 "target_value"	:	"目标值",
 "wait_time_seconds"	:	"等待时间（秒）",
 "greater_than"	:	"大于",
 "modulus_greater_than"	:	"模数大于",
 "lesser_than"	:	"小于",
 "modulus_lesser_than"	:	"模数小于",
 "equal_to"	:	"等于",
 "not_equal_to"	:	"不等于",
 "modulus_equal_to"	:	"模数等于",
 "greater_than_equal_to"	:	"大于等于",
 "modulus_greater_than_equal_to"	:	"模数大于等于",
 "lesser_than_equal_to"	:	"小于等于",
 "modulus_lesser_than_equal_to"	:	"模数小于等于",
 "bit_high"	:	"高位",
 "bit_low"	:	"低位",
 "it_should_be_a_positive_whole_number"	:	"应该是一个正整数",
 "it_should_be_a_positive"	:	"应该是一个正数",
 "create_process"	:	"创建流程",
 "edit_process"	:	"编辑流程",
 "status_rule"	:	"状态规则",
 "create_process_status_rule"	:	"创建流程状态规则",
 "tag_type"	:	"标签类型",
 "process_status"	:	"流程状态",
 "machines"	:	"机器",
 "running"	:	"运行中",
 "idling"	:	"空闲",
 "offline"	:	"离线",
 "no_machines_configured"	:	"没有配置机器",
 "delete_process"	:	"删除流程",
 "add_machine_to_process"	:	"将机器添加到流程",
 "add"	:	"添加",
 "search"	:	"搜索",
 "switch_to"	:	"切换到",
 "switch_to_base_org"	:	"切换到基础组织",
 "switch"	:	"开关",
 "create_new_organisation"	:	"创建新的组织",
 "organisation_name"	:	"组织名称",
 "organisation_type"	:	"组织类型",
"country": "国家",
"country_code": "国家代码",
"state":"州/国家",
 "clear"	:	"清除",
 "change_organisation"	:	"更改组织",
 "organisation_id"	:	"组织ID",
 "update_your_oem_logo"	:	"更新您的OEM标识",
 "select_a_logo_to_upload"	:	"选择一个要上传的标识",
 "submit_logo"	:	"提交标识",
 "create_workspace"	:	"创建工作空间",
    "hub_id": "集線器編號",
    "hub":"中心",
 "name_must_be_less_than_45_characters"	:	"名称必须少于45个字符",
 "id_is_required"	:	"ID是必填项",
 "no_space_allowed"	:	"不允许空格",
 "id_must_be_less_than_6_characters"	:	"ID必须少于6个字符",
 "edit_workspace"	:	"编辑工作空间",
 "delete_workspace"	:	"删除工作空间",
 "add_modbus_device"	:	"添加Modbus设备",
 "add_device"	:	"添加设备",
 "oops"	:	"哎呀，失败了",
 "ok"	:	"Ok",
 "slave_id"	:	"从机ID",
 "device_type"	:	"设备类型",
 "connection_type"	:	"连接类型",
 "mGate"	:	"mGate",
 "make"	:	"制造",
 "model"	:	"型号",
 "name_must_be_less_than_33_characters"	:	"名称必须少于33个字符",
 "name_must_be_less_than_11_characters"	:	"名称必须少于11个字符",
 "it_should_be_a_valid_slave_id"	:	"应该是有效的从机ID",
 "name_must_be_less_than_4_digits"	:	"名称必须少于4位数字",
 "modbus_tcp"	:	"ModbusTCP协议",
 "modbus_rtu"	:	"ModbusRTU协议",
 "add_virtual_device"	:	"添加虚拟设备",
 "add_mqtt_device"	:	"添加MQTT设备",
 "topic"	:	"主题",
 "identifier"	:	"标识符",
 "identifier_value"	:	"标识符值",
 "name_must_be_less_than_20_characters"	:	"名称必须少于20个字符",
 "no_special_characters_or_space_allowed"	:	"不允许特殊字符或空格",
 "must_be_less_than_15_characters"	:	"必须少于15个字符",
 "create_role"	:	"创建角色",
 "role"	:	"角色",
 "role_is_required"	:	"角色是必填项",
 "role_must_be_less_than_40_characters"	:	"角色名称必须少于40个字符",
 "role_should_not_contain_white_characters"	:	"角色名称不应包含空白字符",
 "comparison"	:	"比较",
 "historical_time_of_day_comparison_parameter"	:	"历史每日具体时段能耗比较-参数",
 "device_parameter"	:	"设备参数",
 "date_1"	:	"日期1",
 "date_2"	:	"日期2",
 "tod_comparison_report"	:	"每日具体时段比较报告",
 "time_frame"	:	"时间范围",
 "download_table"	:	"下载表格",
 "table"	:	"表格",
 "showMinMax_values"	:	"显示最小和最大值",
 "usage_or_average"	:	"使用情况或平均值",
 "min_value"	:	"最小值",
 "last_12_weeks"	:	"近12周",
 "last_6_months"	:	"近6个月",
 "last_12_months"	:	"近12个月",
 "year_5"	:	"5年",
 "machinewise_comparison"	:	"机器比较",
 "timeFrame"	:	"时间框架",
 "it_should_be_a_date"	:	"应该是一个日期",
 "time_of_day_parameter"	:	"每日具体时段能耗-参数",
 "tod_report"	:	"每天具体时段能耗报告",
 "in_this_timeframe"	:	"在这个时间范围内",
 "average"	:	"平均值",
 "maximum"	:	"最大值",
 "minimum"	:	"最小值",
 "energy_usage_trend"	:	"能源使用趋势",
 "standard_deviation"	:	"标准偏差",
 "statistical_mode"	:	"统计模式",
 "production_logs"	:	"生产日志",
 "capacity_logs"	:	"容量日志",
 "dynamic_logs"	:	"动态日志",
 "pollutants"	:	"污染物",
 "group_by"	:	"分组依据",
 "generate_grouped_trends"	:	"生成分组趋势",
 "tCo2"	:	"碳排放量",
 "scope_1"	:	"范围1",
 "scope_2"	:	"范围2",
 "scope_3"	:	"范围3",
 "source"	:	"来源",
 "type"	:	"类型",
 "user_access_log"	:	"用户访问日志",
 "user_logs"	:	"用户日志",
 "fetch_logs"	:	"获取日志",
 "update_profile"	:	"更新资料",
 "lang"	:	"语言",
 "theme"	:	"主题",
 "phone"	:	"电话",
 "update"	:	"更新",
 "update_password"	:	"更新密码",
 "change_password"	:	"修改密码",
 "old_password"	:	"旧密码",
 "new_password"	:	"新密码",
 "confirm_password"	:	"确认密码",
 "password_must_be_more_than_8_characters"	:	"密码必须超过8个字符",
 "passwords_do_not_match"	:	"密码不匹配",
 "add_hub"	:	"添加Hub",
 "id_must_be_less_than_4_characters"	:	"ID必须少于4个字符",
 "add_maintainer"	:	"添加维护者",
 "add_parent_org"	:	"添加上级组织",
 "add_parent_to_organisation"	:	"将上级添加到组织",
 "parent_org_id"	:	"上级组织ID",
 "add_maintainer_to_organization"	:	"将维护者添加到组织",
 "maintainer_id"	:	"维护者ID",
 "update_organisation_details"	:	"更新组织详细信息",
 "update_your_organization_details"	:	"更新您的组织详细信息",
 "default_email"	:	"默认电子邮件",
 "picker_in_menu"	:	"菜单中的选择器",
 "param_diagnostics"	:	"参数诊断",
 "show_logo"	:	"显示标识",
 "show_oem_logo"	:	"显示OEM标识",
 "organisation_alias"	:	"组织别名",
 "update_organisation_alias"	:	"更新组织别名",
 "update_org_alias"	:	"更新组织别名",
 "update_your_organization_logo"	:	"更新您的组织标识",
 "select_a_logo"	:	"选择一个标识",
 "update_logo"	:	"更新标识",
 "create_uam_user"	:	"创建UAM用户",
 "overview"	:	"概览",
 "batches"	:	"批次",
 "track_batch"	:	"追踪批次",
 "batch_movement"	:	"批次移动",
 "production_planner"	:	"生产计划员",
 "show_unshipped_only"	:	"仅显示未发货记录",
 "view_logs"	:	"查看日志",
 "params"	:	"参数",
 "add_filter"	:	"添加筛选",
 "filters"	:	"筛选",
 "batch_id"	:	"批次ID",
 "show_details"	:	"显示详细信息",
 "tracing_logs"	:	"追踪日志",
 "quantity"	:	"数量",
 "location_event_type"	:	"位置事件类型",
 "fetch_plans"	:	"获取计划",
 "sku"	:	"SKU",
 "code"	:	"代码",
 "add_production_plans"	:	"添加生产计划",
 "date"	:	"日期",
 "from_time"	:	"开始时间",
 "to_time"	:	"结束时间",
 "activities"	:	"活动",
 "task_name"	:	"任务名称",
 "task_details"	:	"任务详情",
 "start_date"	:	"开始日期",
 "end_date"	:	"结束日期",
 "assignee"	:	"被分配人",
 "manager"	:	"经理",
 "activity"	:	"活动",
 "activity_type"	:	"活动类型",
 "escalation_matrix"	:	"升级矩阵",
 "maintenance_activity"	:	"维护活动",
 "em_level"	:	"紧急情况级别",
 "delay"	:	"延迟",
 "user_group"	:	"用户组",
 "mins_5"	:	"5分钟",
 "mins_10"	:	"10分钟",
 "mins_15"	:	"15分钟",
 "mins_30"	:	"30分钟",
 "hour_1"	:	"1小时",
 "hour_6"	:	"6小时",
 "day_1"	:	"1天",
 "day"	:	"天",
 "day_3"	:	"3天",
 "insights"	:	"洞察",
 "create_emailer_group"	:	"创建邮件组",
 "notification_type"	:	"通知类型",
 "email"	:	"电子邮件",
 "sms"	:	"短信",
 "create_machine_group"	:	"创建机器组",
 "create_param_group"	:	"创建参数组",
 "create_user_group"	:	"创建用户组",
 "create_module"	:	"创建模块",
 "busbar_temp_module"	:	"母线温度模块",
 "power_quality_module"	:	"电能质量模块",
 "esg_module"	:	"ESG模块",
 "edge_hub"	:	"边缘Hub",
 "tolerance"	:	"公差",
 "ambient_temp_input_type"	:	"环境温度输入类型",
 "ambient_temp"	:	"环境温度",
 "workspace_ambient_temp"	:	"工作空间环境温度",
 "device_ambient_temp"	:	"设备环境温度",
 "r_temperature"	:	"R温度",
 "y_temperature"	:	"Y温度",
 "b_temperature"	:	"B温度",
 "n_temperature"	:	"N温度",
 "rated_current"	:	"额定电流",
 "no_load_current"	:	"无负载电流",
 "r_current"	:	"R电流",
 "y_current"	:	"Y电流",
 "b_current"	:	"B电流",
 "n_current"	:	"N电流",
 "delete_activity"	:	"删除活动",
 "substation_voltage_kv"	:	"变电站电压(千伏)",
 "transformer_rating_mva"	:	"变压器额定容量(兆伏安)",
 "percentage_impedance_of_the_transformer"	:	"变压器的阻抗百分比",
 "voltage_rv_y"	:	"电压RV(Y)",
 "voltage_yb_v"	:	"电压YB(V)",
 "voltage_br_v"	:	"电压BR(V)",
 "current_r_a"	:	"电流R(安)",
 "current_y_a"	:	"电流Y(安)",
 "current_b_a"	:	"电流B(安)",
 "thd_i"	:	"总谐波失真I",
 "thd_v"	:	"总谐波失真V",
 "thd_i1"	:	"总谐波失真I1",
 "active_power_kw"	:	"有功功率(千瓦)",
 "apparent_power_kva"	:	"视在功率(千伏安)",
 "reactive_power_kvar"	:	"无功功率(千乏)",
 "active_energy_kwh"	:	"有功电能(千瓦时)",
 "apparent_energy_kvah"	:	"视在电能(千伏时)",
 "pf"	:	"功率因数",
 "text"	:	"文本",
 "energy_source"	:	"能源来源",
 "scope"	:	"范围",
 "system_logs"	:	"系统日志",
 "system_logbook"	:	"系统日志本",
 "esg_data_log"	:	"ESG数据日志",
 "add_esg_data"	:	"添加ESG数据",
 "add_esg_data_log"	:	"添加ESG数据日志",
 "source_type"	:	"来源类型",
 "unit_of_measurement"	:	"测量单位",
 "from_date_optional"	:	"开始日期(可选)",
 "emission_factor"	:	"排放因子 (kgCO2)",
 "create_dynamic_logger"	:	"创建动态记录器",
 "dynamic_log"	:	"动态日志",
 "primary_field_label"	:	"主要字段标签",
 "primary_field_type"	:	"主要字段类型",
 "primary"	:	"主要",
 "additional_fields"	:	"附加字段",
 "field_label"	:	"字段标签",
 "field_type"	:	"字段类型",
 "choose_list"	:	"选择列表",
 "list"	:	"列表",
 "create_capacity_logger"	:	"创建容量记录器",
"capacity_log"	:	"容量日志",
"create"	:	"创建",
"create_production_logger"	:	"创建生产记录器",
"production_log"	:	"生产日志",
"production_logger"	:	"生产记录器",
"input_type"	:	"输入类型",
"input"	:	"输入",
"input_name"	:	"输入名称",
"output_type"	:	"输出类型",
"output"	:	"输出",
"output_name"	:	"输出名称",
"output_params"	:	"输出参数",
"compute_production"	:	"计算生产",
"compute_energy"	:	"计算能源",
"energy_param_type"	:	"能源参数类型",
"energy_device"	:	"能源设备",
"energy_param"	:	"能源参数",
"production_operator"	:	"生产操作符",
"production_correction_factor"	:	"生产校正因子",
"output_operator"	:	"输出操作符",
"output_correction_factor"	:	"输出校正因子",
"input_operator"	:	"输入操作符",
"input_correction_factor"	:	"输入校正因子",
"energy_unit"	:	"能源单位",
"production_unit"	:	"生产单位",
"production"	:	"生产",
"create_data_logger"	:	"创建数据记录器",
"data_log"	:	"数据日志",
"add_param"	:	"添加参数",
"param_id"	:	"参数ID",
"create_batch_production_logger"	:	"创建批次生产记录器",
"batch_tracing_log"	:	"批次追踪日志",
"log_name"	:	"日志名称",
"location"	:	"位置",
"info"	:	"信息",
"remarks"	:	"备注",
"code_will_be_available_to_log_by_default"	:	"默认情况下代码将可用于记录",
"fields"	:	"字段",
"additional_info"	:	"额外信息",
"action"	:	"操作",
"report_type"	:	"报告类型",
"create_parameter_report"	:	"创建参数报告",
"data_processing"	:	"数据处理",
"addition"	:	"加法",
"singular"	:	"单数",
"report"	:	"报告",
"energy_parameters_kwh"	:	"能源参数（千瓦时)",
"apparent_energy_KVAh"	:	"视在电能（千伏安时）",
"maximum_demand_MD"	:	"最大需求（MD)",
"excel_frequency"	:	"Excel频率",
"multiplier"	:	"乘数",
"single"	:	"单一",
"incomers"	:	"进入者",
"sections"	:	"部分",
"energy_distribution_report"	:	"能源分配报告",
"root_machine"	:	"根机器",
"tariff_on"	:	"关税开启",
"add_root_common_machine"	:	"添加根机器和普通机器",
"common_machine"	:	"普通机器",
"energy_bill_distribution_report"	:	"能源账单分配报告",
"create_consolidated"	:	"创建综合报告",
"choose_report"	:	"选择报告",
"id"	:	"ID",
"timeframed_sec"	:	"时间框架秒",
"create_dynamic_excel_report"	:	"创建动态Excel报告",
"update_dynamic_excel_report"	:	"更新动态Excel报告",
"add_sheet"	:	"添加工作表",
"sheet_name"	:	"工作表名称",
"sheet"	:	"工作表",
"sheets"	:	"工作表",
    "esg_scope_logging":"ESG 範圍記錄",
    "targets":"目標",
"sheet_id"	:	"工作表ID",
"delete_sheet"	:	"删除工作表",
"report_name"	:	"报告名称",
"update_details"	:	"更新详细信息",
"unit"	:	"单位",
"parameters"	:	"参数",
"send_excel_file"	:	"发送Excel文件",
"update_excel_template_based_report"	:	"更新基于Excel模板的报告",
"create_excel_template_based_report"	:	"创建基于Excel模板的报告",
"upload_excel_template_xlsx"	:	"上传Excel模板（xlsx)",
"param_equation"	:	"参数方程",
"edit_sheets"	:	"编辑工作表",
"row_no"	:	"行号",
"column_no"	:	"列号",
"row_number"	:	"行号",
"colummn_number"	:	"列号",
"no_sheets_added"	:	"未添加工作表",
"process_based_usage_sheet"	:	"基于流程的使用情况表",
"process"	:	"流程",
"process_label"	:	"流程标签",
"add_tag_types"	:	"添加标签类型",
"add_process_to_list"	:	"将流程添加到列表",
"machine_tag_based_daily_data_sheet"	:	"基于机器标签的每日数据表",
"machine_label"	:	"机器标签",
"add_machine_to_list"	:	"将机器添加到列表",
"add_tag_type_to_list"	:	"将标签类型添加到列表",
"shift_based_usage_sheet"	:	"基于班次的使用情况表",
"status_for_process"	:	"流程",
"status_for_machine"	:	"机器",
 "shift"	:	"班次",
"transpose"	:	"转置",
"process_based_timeframed_data_sheet"	:	"基于流程的时间框架数据表",
 "add_pocess_to_list"	:	"将流程添加到列表",
 "tag_types"	:	"标签类型",
 " consolidated_shift_based_usage_data_sheet"	:	"综合班次使用数据表",
 "date_format"	:	"日期格式",
 "include_process_total"	:	"包括流程总计",
"machine_group_based_usage_sheet"	:	"基于机器组的使用情况表",
"machine_group_label"	:	"机器组标签",
"machine_group"	:	"机器组",
"add_machine_group_to_list"	:	"将机器组添加到列表",
"parameter_based_usage_data_sheet"	:	"基于参数的使用数据表",
"show_month_start&end_data"	:	"显示每月开始和结束数据",
"statistics"	:	"统计",
"add_param_to_list"	:	"将参数添加到列表",
"parameter_group_based_timeframed"	:	"基于参数组的时间框架",
"param_group_label"	:	"参数组标签",
"param_group"	:	"参数组",
"add_param_group_to_list"	:	"将参数组添加到列表",
"dynamic_logger_based_data_sheet"	:	"基于动态记录器的数据表",
"dynamic_log_label"	:	"动态日志标签",
"parameter_equation_based_sheet"	:	"基于参数方程的数据表",
"data_source"	:	"数据源",
"equation_label"	:	"方程标签",
"add_equation_to_list"	:	"将方程添加到列表",
"absolute_values"	:	"绝对值",
"consumption_based_sheet"	:	"基于消耗的表格",
"parameter_name"	:	"参数名称",
"statistical_analysis_based_sheet"	:	"基于统计分析的表格",
"create_sheet_column"	:	"创建工作表列",
"column_label"	:	"列标签",
"machine_id"	:	"机器ID",
"create_machine_list"	:	"创建机器列表",
"create_sheets"	:	"创建工作表",
"create_shift"	:	"创建班次",
"description_optional"	:	"描述（可选)",
"create_sku"	:	"创建SKU",
"create_location"	:	"创建位置",
"location_type"	:	"位置类型",
"from_location"	:	"从",
 "to_location"	:	"到",
"event_type"	:	"事件类型",
"create_location_event_type"	:	"创建位置事件类型",
"create_list"	:	"创建列表",
"create_param_equation"	:	"创建参数方程",
"positive_params"	:	"正参数",
"params_to_be_substracted"	:	"要减去的参数",
"create_combo_param"	:	"创建组合参数",
"inter_param_operator"	:	"交互参数操作符",
 "correction_operator"	:	"校正操作符",
 "correction_operand"	:	"校正操作数",
 "create_calculated_param"	:	"创建计算参数",
 "calculated_param"	:	"计算参数",
 "correction_factor"	:	"校正因子",
 "param1_type"	:	"参数1类型",
 "param1"	:	"参数1",
 "param1_operator"	:	"参数1操作符",
 "param1_operand"	:	"参数1操作数",
 "param2_type"	:	"参数2类型",
 "param2"	:	"参数2",
 "param2_operator"	:	"参数2操作符",
 "param2_operand"	:	"参数2操作数",
 "create_esg_scope_conversion"	:	"创建ESG范围转换",
 "category"	:	"类别",
 "get_from_param"	:	"从参数获取",
 "choose_param"	:	"选择参数",
 "create_target"	:	"创建目标",
 "create_word"	:	"创建单词",
 "words"	:	"单词",
 "choose_OEM"	:	"选择OEM",
 "get_the_models"	:	"获取模型",
 "get_oem_devices"	:	"获取OEM设备",
    "oem_devices": "原始設備製造商設備",
 "model_number"	:	"型号编号",
 "register"	:	"寄存器",
 "add_modbus_gateway"	:	"添加Modbus网关",
" add_modbus_TCP_gateway"	:	"添加ModbusTCP网关",
"IPAddress"	:	"IP地址",
"port_number"	:	"端口号",
"HubID"	:	"Hub ID",
"baud_rate"	:	"波特率",
"parity"	:	"奇偶校验",
"stop_bit"	:	"停止位",
"update_wifi"	:	"更新Wifi",
"SSID"	:	"SSID",
"password"	:	"密码",
"restart_enture"	:	"重启Enture",
"force_update_configs"	:	"强制更新配置",
"activity_name"	:	"活动名称",
"board"	:	"面板",
"calender"	:	"日历",
"maintenance"	:	"维护",
"welcome"	:	"欢迎",
"logout"	:	"注销",
"help"	:	"帮助",
"accounts"	:	"账户",
"organisation"	:	"组织",
"pointer_color_optional"	:	"指针颜色（可选)",
"user_id"	:	"用户ID",
"confirm_new_password"	:	"确认新密码",
"service"	:	"服务",
"send_grid"	:	"发送网格",
"default_enture_view"	:	"默认Enture视图",
"permissions"	:	"权限",
"areas"	:	"区域",
"remark"	:	"备注",
"days"	:	"天数",
"t4-days"	:	"4-天数",
"yesterdays_aqi"	:	"昨天的空气质量指数",
"aqi"	:	"空气质量指数",
"choose_air_quality_device"	:	"选择空气质量测量设备",
"delete_this_device"	:	"删除这个设备",
"air_quality_label"	:	"空气质量标签",
"humidity"	:	"湿度",
"temperature"	:	"温度",
"watcher"	:	"观察者",
"area_reference"	:	"区域参考",
"write_data"	:	"写数据",
"day_of_week"	:	"每周不同天",
 "month"	:	"月份",
 "diagnostics_notification_groups"	:	"诊断通知组",
 "hub_name"	:	"Hub名称",
 "hub_model"	:	"Hub模型",
 "hub_type"	:	"Hub类型",
 "update_your_MQTT_bridge_config"	:	"更新您的MQTT桥接配置",
 "MQTT_bridge_mode"	:	"MQTT桥接模式",
 "broker_address"	:	"代理地址",
 "update_your_OPC_config"	:	"更新您的OPC配置",
 "OPC_client_mode"	:	"OPC客户端模式",
 "OPC_server_URL"	:	"OPC服务器URL",
 "user_auth"	:	"用户认证",
 "mode"	:	"模式",
 "security_policy"	:	"安全政策",
 "Update_your_Edge_Hub_restart_Schedule"	:	"更新您的边缘Hub重启计划",
 "auto_restart"	:	"自动重启",
 "time_24Hrs"	:	"时间（24小时）",
 "modbus_ping_latency"	:	"Modbus Ping延迟",
 "modbus_unhealthy_wait"	:	"Modbus不健康等待",
 "edit_written_log_book"	:	"编辑书面日志本",
 "field_cant_change"	:	"此字段无法更改",
 "re_write"	:	"重写",
 "write_in_logbook"	:	"在日志本上写",
 "write"	:	"写",
 "download_template"	:	"下载模板",
 "write_in_logbook_csv"	:	"通过.csv写入日志本",
 "upload_file"	:	"上传文件",
 "batch"	:	"批次",
 "move_batch"	:	"移动批次",
 "on"	:	"开启",
 "off"	:	"关闭",
 "fast_mode"	:	"快速模式",
 "batch_shipping_details"	:	"批次运输详情",
 "vehicle_no"	:	"车辆编号",
 "net_weight"	:	"净重",
 "actual_weight"	:	"实际重量",
 "vendor"	:	"供应商",
 "loaded_by"	:	"装载用户",
 "verified_by"	:	"验证用户",
 "document_no"	:	"文件编号",
 "multiple_machine_param"	:	"多机器和参数",
 "map_paramid_paramequation_to_row"	:	"将参数ID或参数方程映射到行",
 "add_current_date_to_sheet"	:	"将当前日期添加到表格",
 "create_parameter_from_template"	:	"从模板创建参数",
 "selected_parameters_count"	:	"选定参数计数",
 "restricted_user_access"	:	"受限用户访问",
 "select_all_parameters"	:	"选择所有参数",
 "edit_angular_gauge_widget"	:	"编辑角度仪表组件",
 "add_angular_gauge_widget"	:	"添加角度仪表组件",
 "widget_label"	:	"组件标签",
 "widget_width"	:	"组件宽度",
 "widget_height"	:	"组件高度",
" param_label"	:	"参数标签",
"choose_parameter"	:	"选择参数",
"processing"	:	"处理中",
"edit_banner_widget"	:	"编辑横幅组件",
"add_banner_widget"	:	"添加横幅组件",
"edit_bartd_widget"	:	"编辑条形图TD组件",
"add_bar_widget"	:	"添加条形图组件",
"yaxis_label"	:	"Y轴标签",
"y1axis_label": "Y1 - 轴标签",
"y2axis_label": "Y1 - 轴标签",
    "y1axis":"軸",
    "y2axis":"軸",
"edit_dual_axis_bar_trend_widget": "编辑双轴条形趋势小部件",
"add_dual_axis_bar_trend_widget": "添加双轴条形趋势小部件",
"y1_graph_type": "Y1 轴图形类型",
"y2_graph_type": "Y2 轴图形类型",
"idealValue"	:	"理想值",
"idealLabel"	:	"理想标签",
"add_parameters"	:	"添加参数",
"add_equation"	:	"添加方程",
"add_params"	:	"添加参数",
"enter_of_machines"	:	"输入机器数量",
"edit_bar_trend_td_widget"	:	"编辑条形趋势TD组件",
"add_bar_trend_td_widget"	:	"添加条形趋势TD组件",
"graph_type"	:	"图形类型",
"aggregation"	:	"聚合",
"default_graph_type"	:	"默认图形类型",
"bar_mode"	:	"条形模式",
"show_ideal_line"	:	"显示理想线",
"equations"	:	"方程",
"edit_bitwise_word_widget"	:	"编辑位字段字组件",
"add_bitwise_word_widget"	:	"添加位字段字组件",
"show_bit_status"	:	"显示位状态",
"edit_bullet_gauge_widget"	:	"编辑子弹图仪表组件",
"add_bullet_gauge_widget"	:	"添加子弹图仪表组件",
"reference_value_for"	:	"参考值",
"gauge_type"	:	"仪表类型",
"indicator_color"	:	"指示器颜色",
"busbar_temperature_graph_widget"	:	"母线温度图形组件",
"busbar_module"	:	"母线模块",
"edit_consumption_gauge_widget"	:	"编辑消耗仪表组件",
"add_consumption_gauge_widget"	:	"添加消耗仪表组件",
"linearGauge"	:	"线性仪表",
"show_target"	:	"显示目标",
"healthy_on_below_target"	:	"实际健康状况低于设定目标",
"edit_consumption_pie_widget"	:	"编辑消耗饼图组件",
"add_consumption_pie_widget"	:	"添加消耗饼图组件",
"add_color_break_point"	:	"添加颜色断点",
"custom_color"	:	"自定义颜色",
"color"	:	"颜色",
"edit_heat_map_widget"	:	"编辑热力图组件",
"add_heat_map__widget"	:	"添加热力图组件",
"choose_equation"	:	"选择方程",
"label_star"	:	"标签*:",
"color_star"	:	"颜色*",
"operator1_star"	:	"操作符1*",
"value1_star"	:	"数值1*",
"operator2_star"	:	"操作符2*",
"actions"	:	"操作",
"condition"	:	"条件",
"status_color"	:	"状态颜色",
"text_view"	:	"文本视图",
"edit_process_Status_widget"	:	"编辑流程状态组件",
"add_process_Status_widget"	:	"添加流程状态组件",
"edit_machine_Status_widget"	:	"编辑机器状态组件",
"add_machine_Status_widget"	:	"添加机器状态组件",
"choose_process"	:	"选择流程",
"choose_machine"	:	"选择机器",
"table_header_label"	:	"表格标题标签",
"edit_table_TD_widget"	:	"编辑表格TD组件",
"add_table_TD_widget"	:	"添加表格TD组件",
"configure_table"	:	"配置表格",
"edit_target_based_trend_widget"	:	"编辑基于目标的趋势组件",
"add_target_based_trend_widget"	:	"添加基于目标的趋势组件",
"target"	:	"目标",
"target_line_label"	:	"目标线标签",
"edit_usage_score_card"	:	"编辑使用情况记分卡组件",
"add_usage_score_card"	:	"添加使用情况记分卡组件",
"compare_to"	:	"比较到",
"assign_color"	:	"分配颜色",
"enter"	:	"输入",
"gauge_to_dashboard"	:	"仪表到仪表盘",
"widget_types"	:	"组件类型",
"from_value"	:	"从",
"to_value"	:	"到",
"machine_status"	:	"机器状态",
"deviceA_type"	:	"设备A类型",
"templates"	:	"模板",
"add_triggers_to_parameter"	:	"向参数添加触发器",
"triggers"	:	"触发器",
"show_filter"	:	"显示筛选",
"view_id"	:	"视图ID",
"move_up"	:	"上移",
"move_down"	:	"下移",
"multiplier_types"	:	"乘数类型",
"energy_report"	:	"能源报告",
"add_machine_param"	:	"添加机器参数",
"aliasing"	:	"别名",
"no_zero_read"	:	"未识别到零",
"remove_negative_entropy"	:	"移除负熵",
"calculate_consumption"	:	"计算消耗",
"disabled"	:	"禁用",
"limiter_value"	:	"限制值",
"machine_scroll_view_page"	:	"机器滚动视图页面",
"create_mannual_parameter"	:	"创建手动参数",
"restrict_user_access"	:	"限制用户访问",
"add_device_parameter"	:	"添加设备参数",
"entropy"	:	"熵",
"idle_latency"	:	"空闲延迟",
"add_OPC_parameter"	:	"添加OPC参数",
"node_id"	:	"节点ID",
"byte_format"	:	"字节格式",
"param_scroll_view_page"	:	"参数滚动视图页面",
"timeframed_sec_report"	:	"时间框架SEC报告",
"prod_unit"	:	"生产单位",
"SEC_unit"	:	"SEC单位",
"energy_parameters"	:	"能源参数",
"prod_param_type"	:	"生产参数类型",
"production_parameters"	:	"生产参数",
"no_of_intervals"	:	"间隔数量",
"granular_data"	:	"细粒度数据",
"csv"	:	"CSV",
"delete_rule"	:	"删除规则",
"enable_disable_all_parameters"	:	"启用/禁用所有参数",
"disable"	:	"禁用",
"enable"	:	"启用",
"edit_computed_parameter"	:	"编辑计算参数",
"quantization"	:	"量化",
 "command"	:	"命令",
 "register_size"	:	"注册器大小",
 "consumer_ID"	:	"消费者ID",
 "site_id"	:	"站点ID",
 "get_data"	:	"获取数据",
 "update_param_type"	:	"更新参数类型",
 "write_in_data_logbook"	:	"在数据日志本上写",
 "daily_data"	:	"每日数据",
 "log_entry"	:	"日志条目",
 "enable_Scroll_mode"	:	"启用滚动模式",
 "multiple_areas"	:	"多个区域",
 "time_of_day"	:	"具体时间段",
 "capacity_logger"	:	"容量记录器",
 "provider"	:	"提供者",
 "total_sites_received_bills_MTD"	:	"本月至今站点已接收账单总计",
 "sites_yet_to_receive_bills"	:	"尚未接收账单的站点",
 "total_sites"	:	"总站点数量",
 "sites_received_bills_MTD"	:	"%本月至今站点已接收账单",
 "total"	:	"总计",
 "total_bills_received_mtd"	:	"本月至今接收的总账单",
 "arrear_1"	:	"拖欠1",
 "arrear_2"	:	"拖欠2",
 "no_reading"	:	"无读数",
 "bills_received_in_5_days"	:	"最近5天接收的账单",
 "site_wise_status"	:	"除OK状态外的站点状态",
 "site_wise_final_summary"	:	"站点最终总结",
 "source_link"	:	"来源链接",
 "data_type"	:	"数据类型",
 "select_an_image_to_upload"	:	"选择要上传的图片",
 "user_group_Id"	:	"用户组ID",
 "user_group_name"	:	"用户组名称",
 "delay_in_mins"	:	"延迟（分钟)",
 "delete_task"	:	"删除任务",
 "watchers"	:	"观察者",
 "comments"	:	"注释",
 "asset_under_maintenance"	:	"正在维护的资产",
 "start_time"	:	"开始时间",
 "end_time"	:	"结束时间",
 "download_latest_usage_table"	:	"下载最新的使用情况表格",
 "tag"	:	"标签",
 "add_target_data"	:	"添加目标数据",
 "edit_target"	:	"编辑目标",
 "delete_target"	:	"删除目标",
 "prop"	:	"属性",
 "excel_template_based_report"	:	"基于Excel模板的报告",
 "configured_hub"	:	"已配置的Hub",
 "configured_relay"	:	"已配置的继电器",
 "desired_state"	:	"期望状态",
 "configured_notification_group"	:	"已配置的通知组",
 "email_ids"	:	"电子邮件ID",
 "logs"	:	"日志",
 "unit_id"	:	"单元ID",
 "download_report"	:	"下载报告",
 "frequency"	:	"频率",
 "ip"	:	"网络协议地址",
 "edit_wifi"	:	"编辑无线网络",
 "details"	:	"详细信息",
 "connected_to"	:	"已连接到",
 "software_version"	:	"软件版本",
 "mac_address"	:	"MAC地址",
 "opc_client_enabled"	:	"OPC客户端已启用",
 "MQTT_bridge_enabled"	:	"MQTT桥接已启用",
 "hub_specification"	:	"Hub规格",
 "hub_details"	:	"Hub详情",
 "restart_schedule"	:	"重启计划",
 "restart_device"	:	"重启设备",
 "set_up_restart_time"	:	"设置重启时间",
 "connectivity_logs"	:	"连接日志",
 "internal_cache_size"	:	"内部缓存大小",
 "default"	:	"默认",
 "configs"	:	"配置",
 "processed"	:	"已处理",
 "space_available"	:	"可用空间",
 "kb"	:	"KB",
 "mb"	:	"MB",
 "last_ping"	:	"上次ping",
 "toggle"	:	"切换",
 "configured_creds"	:	"已配置的凭据",
 "psk"	:	"PSK",
 "set_value"	:	"设定值",
 "time_current_time"	:	"时间（如果未提及将采用当前时间）",
 "Set"	:	"设定",
 "health"	:	"健康",
 "port"	:	"端口",
 "mqtt_params"	:	"MQTT参数",
 "delete_device"	:	"删除设备",
 "view_details"	:	"查看详细信息",
 "parameter_id"	:	"参数ID",
" email_address"	:	"电子邮件地址",
"attached_triggers"	:	"附加的触发器",
"parameter_details"	:	"参数详情",
"enable_write"	:	"启用写入",
"disable_write"	:	"禁用写入",
"offset"	:	"偏移量",
"no_of_parameters"	:	"参数数量",
"creation_time"	:	"创建时间",
"not_configured"	:	"未配置",
"add_rule"	:	"添加规则",
"sl_no"	:	"序号",
"targeted_by_value"	:	"按数值定位",
"configured_action"	:	"已配置的操作",
"add_action"	:	"添加操作",
"action_id"	:	"操作ID",
"delete_user"	:	"删除用户",
"bit"	:	"Bit",
"low"	:	"低",
"high"	:	"高",
"esg_scope_conversion"	:	"ESG范围转换",
"batch_production_logs"	:	"批量生产日志",
"esg_data_logs"	:	"ESG数据日志",
"remove_from_dashboard"	:	"从仪表盘移除",
"data_logs"	:	"数据日志",
"select_device"	:	"选择设备",
"fetch_connectivity_logs"	:	"获取连接日志",
"hub_internet_connectivity_logs"	:	"Hub互联网连接日志",
"modbus_gateways"	:	"Modbus网关",
"device_switched_on"	:	"设备已开启",
"device_switched_off"	:	"设备已关闭",
"parameter_report"	:	"参数报告",
"timeframed_specific_energy_consumption_report"	:	"特定时间段能源消耗报告",
"ebill_distribution_report"	:	"电子账单分配报告",
"root_user_management"	:	"根用户管理",
"user_acces_management"	:	"用户访问管理",
"inference"	:	"推理",
"turn_off"	:	"关闭",
"turn_on"	:	"打开",
"diagram_pallette"	:	"图表调色板",
"add_component"	:	"添加组件",
"components"	:	"组件",
"add_text"	:	"添加文本",
"add_realtime_data"	:	"添加实时数据",
"shapes"	:	"图形",
"lines"	:	"线条",
"text_realtime_component"	:	"文本实时组件",
"shapes_pallette"	:	"图形调色板",
"lines_pallette"	:	"线条调色板",
"click_choose"	:	"点击并选择要添加到单线图的形状",
"choose_line"	:	"选择要添加到单线图的线条",
"choose_image"	:	"选择图片",
"upload_image_to_add"	:	"上传要添加到SLD的图片",
"add_realtime_textdata"	:	"向SLD添加实时数据或文本数据",
"update_user"	:	"更新用户",
"add_watcher"	:	"添加观察者",
"premise_area"	:	"场所区域",
"create_computed_param"	:	"创建计算参数",
"create_derived_param"	:	"创建派生参数",
"proceed_to_ship"	:	"进行发货",
"esg_data_collection"	:	"ESG数据集合",
"esg_scope_details"	:	"ESG范围详情",
"select_custom_date_range"	:	"选择自定义日期范围",
"days_365"	:	"365天",
"days_180"	:	"180天",
"days_30"	:	"30天",
"create_user_for_SSO"	:	"为SSO创建用户",
"add_widget"	:	"添加组件",
"add_machine_status_rules"	:	"添加机器状态规则",
"add_trigger"	:	"添加触发器",
"add_logged_parameter"	:	"添加已记录的参数",
"create_mannual_param"	:	"创建手动参数",
"add_opc_param"	:	"添加OPC参数",
"create_write_block"	:	"创建写入区块",
"all_parameters"	:	"所有参数",
"edit_device"	:	"编辑设备",
"edit_modbus_device"	:	"编辑Modbus设备",
"edit_mqtt_device"	:	"编辑Mqtt设备",
"limit_entropy"	:	"限制熵",
"emails"	:	"电子邮件",
"power_factor"	:	"功率因数(PF)",
"tarrif_rate"	:	"关税率",
"apparent_power"	:	"视在功率",
"reactive_power"	:	"无功功率",
"active_power"	:	"有功功率",
"energy_usage_view_page"	:	"能源使用视图页面",
"switch_organisation"	:	"切换组织",
"wireless_creds"	:	"无线凭据",
"latest"	:	"最新",
"value_is"	:	"数值是",
"please_take_necessary_action"	:	"请采取必要的行动",
"edit_role"	:	"编辑角色",
"delete_role"	:	"删除角色",
"today"	:	"今天",
"days_4"	:	"4天",
"due"	:	"到期日",
"move_to_done"	:	"标记为完成",
"power_system_fault"	:	"电力系统故障计数（上周)",
"alarms"	:	"警报",
"electricity_consumption"	:	"电力消耗（本月至今）",
"average_demand"	:	"平均需求",
"water_use_intensity"	:	"用水使用强度(WUI)",
"average_water_cost"	:	"平均水费（每月）",
"water_use_efficiency"	:	"用水使用效率(WUE)",
"batch_logs"	:	"批量日志",
"edit_log"	:	"编辑日志",
"delete_log"	:	"删除日志",
"dates"	:	"日期",
"log_details"	:	"日志详情",
"view"	:	"查看",
"scope1_emission"	:	"范围1排放",
"scope2_emission"	:	"范围2排放",
"lmtd"	:	"上月初至今",
"company_data"	:	"公司数据",
"historian"	:	"数据记录器",
"from_optional"	:	"开始日期（可选）",
"to_optional"	:	"结束日期（可选）",
"trends"	:	"趋势",
"generate_trend"	:	"生成趋势",
"by"	:	"由",
"check_logs"	:	"检查日志",
"workspaces"	:	"工作空间",
"types_of_nodes_connected"	:	"已连接的节点类型",
"types_of_modbus_device_connected"	:	"已连接的Modbus设备类型",
"no_of_registers"	:	"寄存器数量",
"write_register"	:	"写入寄存器",
"setting_view"	:	"设置视图",
"sign_up"	:	"注册",
"reset"	:	"重置",
"mobile_number"	:	"手机号码",
"nebeskie_labs"	:	"Nebeskie实验室",
"please_register_and_send_otp"	:	"请输入您的注册电子邮件ID，然后点击发送一次性密码",
"reset_password"	:	"重置密码",
"back_to_login"	:	"返回登录",
"otp"	:	"一次性密码",
"send_otp"	:	"发送一次性密码",
"org_alias"	:	"组织别名",
"please_stand_by"	:	"请稍候",
"total_usage_this_month"	:	"本月总使用量",
"total_usage_last_month"	:	"上月总使用量",
"generate_report_manually"	:	"手动生成报告",
"energy_distribution_detailed_report"	:	"能源分配详细报告",
"the_report_is_processing"	:	"报告正在处理",
"enter_active_energy_manually"	:	"手动输入有功电能",
"include_others"	:	"包括其他",
"bill_amount"	:	"账单金额",
"yes_delete_it"	:	"是的，删除它",
"create_write_param"	:	"创建写入参数",
"report_list"	:	"报告列表",
"plant_id"	:	"工厂ID",
"plant_name"	:	"工厂名称",
"plant_address"	:	"工厂地址",
"add_selected"	:	"添加选择",
"clear_selection"	:	"清除选择",
"real_time_value"	:	"实时数值",
"default_status"	:	"默认状态",
"default_label"	:	"默认标签",
"default_color_optional"	:	"默认颜色（可选)",
"rule_based_status"	:	"基于规则的状态",
"show_label_only"	:	"仅显示标签",
"show_unit"	:	"显示单位",
"status_rules"	:	"状态规则",
"configure"	:	"配置",
"monthly_usage"	:	"月度使用量",
"meter_consumption_kwh"	:	"电表消耗量（千瓦时)",
"total_unit"	:	"总单位",
"region"	:	"地区",
"average_energy_consumed"	:	"平均能耗",
"average_production"	:	"平均产量",
"average_sec"	:	"平均SEC",
"total_production"	:	"总产量",
"total_energy_consumed"	:	"总能耗",
"max_sec"	:	"最大SEC",
"device_1"	:	"设备1",
"device_parameter_1"	:	"设备参数1",
"device_2"	:	"设备2",
"device_parameter_2"	:	"设备参数2",
"create_param"	:	"创建参数",
"update_tags"	:	"更新标签",
"remove_from_process"	:	"从流程中移除",
"machine_parameters"	:	"机器参数",
"energy"	:	"能源",
"power"	:	"功率",
"remove_from_featured"	:	"从特征中移除",
"other_parameters"	:	"其他参数",
"add_to_featured"	:	"添加到特征",
"logged_parameters"	:	"已记录的参数",
"tags"	:	"标签",
"machine_status_rules"	:	"机器状态规则",
"connected_communication_devices"	:	"已连接的通信设备",
"mqtt_devices"	:	"MQTT设备",
"modbus_devices"	:	"Modbus设备",
"total_no_of_params"	:	"总参数数量",
"edge_devices"	:	"边缘设备",
"machines_configured"	:	"已配置的机器",
"reports_configured"	:	"已配置的报告",
"views_configured"	:	"已配置的视图",
"send_email_notification"	:	"发送电子邮件通知",
"enture_send_email_notification"	:	"让Enture向选定的用户组发送电子邮件通知",
"agree_and_send"	:	"同意并发送",
"ebill"	:	"电子账单",
"monthly_trend"	:	"月度趋势",
"param_view"	:	"参数视图",
"calories"	:	"卡路里",
"param_count_view"	:	"参数计数视图",
"created"	:	"已创建",
"api_data_count_view"	:	"API数据计数视图",
"diesel_emissions"	:	"柴油排放",
"eb_emissions"	:	"EB排放",
"emissions_kg_co2"	:	"排放量千克-二氧化碳",
"kpis"	:	"关键绩效指标",
"jan_23"	:	"1/1/2023",
"feb_23"	:	"2/1/2023",
"mar_23"	:	"3/1/2023",
"apr_23"	:	"4/1/2023",
"may_23"	:	"5/1/2023",
"jun_23"	:	"6/1/2023",
"lpg_emissions"	:	"液化石油气排放",
"petrol_emissions"	:	"汽油排放",
"bullet_gauge"	:	"子弹图仪表",
"equipment"	:	"设备",
"enter_no_of_machines":"输入机器数",
"upload":"上传",
"image_upload":"图片上传",
"derived_parameter":"导出参数",
"value_type":"值类型",
"enable_entropy":"启用熵",
"remove_decimal":"删除小数",
"limiter_entropy":"限制熵",
"show_sku_id":"显示 SKU ID",
"production_start_date":"生产开始日期（可选",
"production_start_time":"生产开始时间（可选）",
"time":"时间",
"temperature_c":"温度（℃）",
"set_temperature_c":"设定温度（℃）",
"no_of_people":"人数",
"co2_level":"二氧化碳浓度",
"aqi_optional":"空气质量指数 可选",
"delete_sheets":"删除工作表",
"root_login":"根登录",
"uam_login":"城市空中交通管理登录",
"login":"登录",
"add_oem_logo":"添加 OEM 徽标",
"devices":"设备",
"derived_data_units":"导出/数据单元",
"roles":"角色",
"engineering":"工程",
"control_panel":"控制面板",
"views":"意见",
"reports_analysis":"报告与分析",
"actions_triggers":"动作和触发器",
"modules":"模块",
"processes":"流程",
"air_quality":"空气质量",
"manufacturing":"生产",
"esg":"环境、社会及治理",
"task_managment":"任务管理",
"sustainability":"可持续发展",
"groups":"用户组",
"log_book":"日志",
"monitor":"监视器",
"enture_settings":"环境设置",
"configuration":"配置",
"bar":"柱形图",
"line":"线型图",
"s7_device":"S7设备",
"rack":"架子",
"slot":"投币口",
"db_number":"数据库编号",
"byte_offset":"字节偏移量",
"bit_offset":"位偏移量",
"data_length":"数据长度",
"val_type":"值类型",
"create_s7_device":"创建S7设备",
"scope_label":"范围",
"client_safety":"客户安全",
"edit":"编辑",
"delete":"删除",
"choose_param_to_monitor":"选择要监控的参数",
"rules":"规则",
"add_to_row":"添加到行",
"add_usage":"添加用法",
"notifications":"通知",
"organisation_details":"组织详情",
"profile":"个人信息",
"user_access":"用户访问",
"admin":"行政",
"shifts":"转变",
"skus":"庫存單位",
    "lists": "清單",
    "param_equations": "參數方程",
    "combo_params": '組合參數',
    "calculated_params": "計算參數",
"locations":"地點",
"locations_event_types"  : "地點 活動類型",
"add_pie_TD_widget":"添加饼图截止日期小部件",
    "edit_pie_TD_widget":"编辑饼图截止日期小部件",
    "legend":"Legend",
    "pie":"Pie",
    "edit_bar_trend_widget":"编辑条形趋势小部件",
    "add_bar_trend_widget":"添加条形趋势小部件",
    "too_long":"太长",
    "trigger_logs":"触发日志",
    "create_trigger":"创建触发器",
    "right_click_to_configure":"右键单击进行配置",
    "width":"宽度",
    "height":"高度",
    "hide_value":"隐藏值",
    "edit_machine":"编辑机器",
    "wait_time":"等待时间",
    "production_date":"生产日期",
    "connectivity_status":"连接状态",
    "production_plans":"生产计划",
    "production_pending":"待生产",
    "pending_shipment":"待托运",
    "trace_logs":"跟踪日志",
    "movement_events":"批量移动事件",
    "entropy_threshold":"熵阈值",
    "threshold_value":"阈值",
    "pending_dispatch":"待发货",
    "dispatched":"已派遣",
    "dispatch_details":"发货详情",
    "dispatch":"发送这个",
    "raw_materials":"原料",
    "pour_qty":"倒出数量",
    "summary":"总结",
    "optional":"选修的",
    "param_mqtt_map":"参数 MQTT 映射",
    "machine_status_mqtt_map":"机器状态 MQTT 映射",
    "edit_trigger_count_table_widget":"编辑触发器计数表小部件",
    "add_trigger_count_table_widget":"添加触发器计数表小部件",
    "count":"伯爵",
    "real_time_consumption":"实时消费",
    "search_view":"按名称查找",
    "multiple_entries":"录入多个记录",
    "combined_parameter":"组合参数",
    "background_color":"背景颜色",
    "resize": "調整大小",
    "remove": "刪除",
    "select_views": "選擇視圖",
    "difference": "不同之處",
    "sum":"和",
    "set_param_type_for_machine": "設定機器的參數類型",
    "temperature_parameters": "溫度參數",
    "area_name":"區域名稱",
    "maintenance_due_on":"維護到期日",
    "days_left":"剩餘天數",
    "enture_insights":"風險洞察",
    "edit_logged_parameter":"編輯記錄的參數",
    "reports":"报告"
    
}