

const colorCodes={
    'teal': '#64FFDA',
    'deeppurple':'#B388FF',
    'cyan': '#18FFFF',
    'green':'#69F0AE',
    'purple': '#E040FB',
    'pink': '#FF4081',
    'lime': '#C6FF00',
    'lightblue': '#40C4FF',
    'red':'#FF1744',
    'yellow': '#FFFF00',
    'lightgreen':'#76FF03',
    'deeporange': '#FF3D00', 
    'greylighten':'#BDBDBD',
     'blue': '#2979FF',
    // 'amber':'#FFD74',
    'orange':'#FF9800',
    'deeppurplelighten':'#D1C4E9',
//    'tealdarken' :'#00897B'
   'tealdarken' :'#26A69A'
}
const colorCodesWhite={

    'indigolighten': '#5C6BC0',
    'purple': '#E040FB',
    'green':'#69F0AE',
    'pink': '#FF4081',
    'teal': '#64FFDA',
    'lime': '#C6FF00',
    'lightblue': '#40C4FF',
    'red':'#FF1744',
    'yellow': '#FFFF00',
    'deeppurple':'#B388FF',
    'lightgreen':'#76FF03',
    'deeporange': '#FF3D00',
    'cyan': '#18FFFF',   
    'greylighten':'#BDBDBD',
     'blue': '#2979FF',
    // 'amber':'#FFD74',
    'orange':'#FF9800',
    'deeppurplelighten':'#D1C4E9'
        
}
const colorNames={
    'green': 'green accent-2',
    'purple': 'purple accent-2',
    'pink': 'pink accent-2',
    'teal':'teal accent-2',
    'lime': 'lime accent-3',
    'lightblue': 'light-blue accent-2',
    'red':'red accent-3',   
    'yellow': 'yellow accent-2',
    'deeppurple':'deep-purple accent-1',
    'lightgreen' :'light-green accent-3',
    'cyan':  'cyan accent-2',
   'deeporange': 'deep-orange accent-3',
   'blue': 'blue accent-3',   
   'amber' :'amber accent-2',
   'greylighten':'grey lighten-1',
   'orange':'orange ',
   'deeppurplelighten':'deep-purple lighten-4',
   'tealdarken':'teal lighten-1'
//    'tealdarken':'teal darken-1'
 
}

const gradColorName=[
    'orange',
    'teal  ',
    'orange lighten-3',
    'teal lighten-3',
    'orange lighten-1',
    'orange lighten-1',
    'torange lighten-4',
    'teal lighten-4',
    'orange darken-1',
    'teal darken-1',
    'orange darken-3',
    'teal darken-3',
]
const gradColorCode=[
'#FF9800',
'#009688',
'#FFCC80',
'#80CBC4',
'#FFA726',
'#26A69A',
'#FFE0B2',
'#80CBC4',
'#FB8C00',
'#00897B',
'#EF6C00',
'#00695C'
]

var colorscaleCodeHeatMap = [
[0,'#40C4FF'],
// [0.1,'#81C784'],
// [0.2,'#66BB6A'],
// [0.3,'#FFF59D'],
// [0.4,'#FFF176'],
[0.5,'#FFEE58'],
// [0.6,'#FFB74D'],
// [0.7,'#FFA726'],
// [0.8,'#FB8C00'],
// [0.9,'#F57C00'],
[1,'#FF4081'],
];
// var colorscaleCodeHeatMap = [
// [0,'#A5D6A7'],
// [0.1,'#81C784'],
// [0.2,'#66BB6A'],
// [0.3,'#FFF59D'],
// [0.4,'#FFF176'],
// [0.5,'#FFEE58'],
// [0.6,'#FFB74D'],
// [0.7,'#FFA726'],
// [0.8,'#FB8C00'],
// [0.9,'#F57C00'],
// [1,'#EF6C00'],
// ];

// const gradColorCode=[
//  #E0F2F1',
// '#B2DFDB',
// '#80CBC4',
// '#4DB6AC',
// '#26A69A',
// '#00897B',
// '#00796B',
// '#00695C',
// '#004D40',
// '#A7FFEB',
// '#64FFDA',
// '#1DE9B6',
// '#00BFA5',
// ]

// const tickfontColor=[
//     '#FDD835'
// ]


export default {
name:'LightColorList',
avatarColorCode:'#4DB6AC',
avatarColorName:'teal lighten-2',
colorTypesName:colorNames,
colorTypesCodes:colorCodes,
mgateList:'#FFF',
isDark:false,
// buttonColorFill:
// cardBorderColor:'#FFC400',
navigation:'#263238',
panelBorderColorCode:'#E8EAF6',
panelBorderColorName:'indigo lighten-5',
cardBorderColor:'#546E7A',
iconColor:'#F44336', // TODO - Need to check & Update
iconBorderColor:'#0cab83', // TODO - Need to check & Update
alertIconColor:'#F44336', // TODO - Need to check & Update
fontcolorTypescodes:['#FDD835'],
appBarColorName:'white',
tableHeaderColorName:'blue-grey lighten-5',//colorNames['deeppurplelighten'],
tableHeaderColorCode:'#ECEFF1',//colorCodes['deeppurplelighten'],
widgettableHeaderColorCode:colorCodes['blue'],
navigation_active_color:'indigo-lighten-1',
navigation_icon:'#FFFFFF',
topBarAccent:'#000000',
topBar:'#E8EAF6',
tabAccent:'#000000',
tab:'#C5CAE9',
foregroundColorName:'indigo lighten-3',//Object.values(colorNames)[0] accent 4 original,
foregroundColorCode:'#9FA8DA',//'#81C784',//Object.values(colorCodes)[0],
expansionPanelHeaderColorName:'white',
expansionPanelHeaderColorCode:'#FFFFFF00',
cardColor:'grey lighten-3',
cardColorCode:'#EEEEEE',
cardColorLoginPage:'grey lighten-3',
graphPoints:colorCodes['lime'],
backgroundColorCode:  '#E8EAF6',//'#EEEEEE' , //'#FAFAFA' ,//,
backgroundColorName:'indigo lighten-5',
background1ColorCode:'#FFFFFF',
background2ColorCode:'#FAFAFA',
alertColor:'#E57373',
successColor:'#A5D6A7',
accentCode:'#000000',
accentName:'black',
bulletGaugeBar:'orange',
colorTypesNameArray:Object.values(colorNames),
colorTypesCodeArray:Object.values(colorCodesWhite),
colorTypesCodeArrayHeatMap:colorscaleCodeHeatMap,
gradientColorCodes:gradColorCode,
gradientColorNames:gradColorName,
submitbtnColor:"#64B5F6",
submitbtn2Color:"#64B5F6",
cancelbtnColor:"#FF8A80",
deletebtnColor:"red",
downloadbtnColor:"light-blue",
inputtextColor:"light-blue-darken-1",
inputtext2Color:"light-blue-darken-1",
tabActiveColor:"light-blue-darken-1",
chipColor:"indigo",
tabStripColor:'grey darken-1',
btnborderColorCode:   "#3949AB",// "#7E57C2",//'#5E35B1',//'#000000',
orgcardColor:'#80CBC4',
tabactiveColor:"#EEFF41",
loggerColor: "#9FA8DA",
tickfontColor: "#263238",
textColor:"#673AB7",
mgateColor:"#D1C4E9",
// esg_module_card:'indigo lighten-4',
esg_module_card:'#C5CAE9',
esg_module_card_text:'#FFFFFF',
esgbtnColor:'000000',
 

dropDownCodeArray: () => {
    let dropDownCodeArrays = []
    let count = 0
    for(let i of Object.keys(colorCodes)){
        let tempObject ={label:i,values:Object.values(colorCodes)[count]}
        dropDownCodeArrays.push(tempObject)
        count = count+1
    }
    return dropDownCodeArrays
},
dropDownNameArray : () => {
    let dropDownNameArrays=[]
    let count = 0
    for(let i of Object.keys(colorNames)){
        let tempObject ={label:i,values:Object.values(colorNames)[count]}
        dropDownNameArrays.push(tempObject)
        count = count+1
    }
    return dropDownNameArrays
},

}