<template>
    <v-container  :dark="$store.getters.getColorPalette().isDark"  fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      
        <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Param Equated Banner Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Param Equated Banner Widget</span>
            </v-col>
        </v-row> -->
        <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="widgetForm" v-model="widgetValid">
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">    
            <v-card-title v-if="edit" class="sub-heading">{{$store.getters.getTextMap().edit_param_equated_banner_widget}}</v-card-title>
            <v-card-title v-else class="sub-heading">{{$store.getters.getTextMap().add_param_equated_banner_widget}}</v-card-title> 
            <v-row>
                <v-col align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field dense outlined v-if="!loading" v-model="widgetForm.label" :counter="30" :rules="[rules.required]" :label="$store.getters.getTextMap().widget_label" required></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="widgetForm.width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                        
                        ></v-select>  
                </v-col>
                 <v-col>
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.height"
                            :items="heightOptions"
                            :label="$store.getters.getTextMap().widget_height"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            :rules="[rules.required]"
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                </v-col>
                <v-col align-self="center">
                    <v-select
                            v-if="!loading"
                            v-model="widgetForm.background_color"
                            :items="$store.getters.getBackgroundColorOptions()"
                            :label="$store.getters.getTextMap().background_color"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            clearable
                          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        >
                        <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                </v-select>
                </v-col>
                <v-col v-if="!disableTimeframe" align-self="center">
                    <v-select
                   :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="form.time_frame"
                        :items="tdOptions"
                        :label="$store.getters.getTextMap().timeFrame"
                        dense
                        outlined
                        :rules="[rules.required]"
                        item-text="label"
                        item-value="value"
                        ></v-select>  
                </v-col>
                <v-col v-if="edit" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                            v-if="!loading"
                            v-model="widgetForm.seq"
                            :counter="3"
                            :label="$store.getters.getTextMap().sequence"
                            dense
                            outlined
                            ></v-text-field>
                </v-col>
            </v-row>
        
        </v-card>

        <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().panelBorderColorCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined>
            <!-- Single Param -->
            <!-- <v-card  :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().background1ColorCode" flat outlined> -->
                <v-card-title sclass="sub-heading">{{ $store.getters.getTextMap().choose_param}}</v-card-title>
                    <v-divider :dark="$store.getters.getColorPalette().isDark"/>
                    <v-container fluid>
                        <v-row no-gutters>
          <v-col align-self="center">
              <span :color="$store.getters.getColorPalette().accentCode">{{ $store.getters.getTextMap().param_type }}</span>
          </v-col>
          <v-spacer></v-spacer>
            <v-col align="right" align-self="center" v-if=" $store.state.user ">
              <v-radio-group v-model="paramType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().param_equation" value="param_equation"></v-radio>
                <v-radio :label="$store.getters.getTextMap().param" value="param"></v-radio>
              </v-radio-group>
            </v-col>
      </v-row>
      <v-row v-if="paramType=='param'">
        <v-col>
            <WorkspaceMachineParamForm  v-on:value="updateParam" />
        </v-col>
      </v-row>
                        <v-row v-if="paramType=='param_equation'">
                                    <v-col>
                                             <v-autocomplete
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.id"
                                            :items="paramEquationOptions"
                                            :label="$store.getters.getTextMap().equations"
                                            dense
                                            outlined
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-autocomplete>
                                           </v-col>
                                           
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                               
                                                    <v-text-field
                                                        v-if="!loading"
                                                        v-model="form.multiplier"
                                                        :counter="30"
                                                        :rules="numberRules"
                                                        :label="$store.getters.getTextMap().multiplier"
                                                        dense 
                                                        outlined
                                                        required
                                                    ></v-text-field>
                                            </v-col> 
                                            <v-col>
                                                <v-autocomplete
                                           :dark="$store.getters.getColorPalette().isDark" 
                                            :color="$store.getters.getColorPalette().inputtextColor"
                                            v-if="!loading"
                                            v-model="form.shift_id"
                                            :items="shiftOptions"
                                            :label="$store.getters.getTextMap().shifts"
                                            dense
                                            outlined
                                            item-text="label"
                                            item-value="value"
                                            required
                                            :rules="objectRules"
                                           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                        ></v-autocomplete>
                            </v-col>
                        </v-row>

                </v-container>
            <!-- </v-card>   -->
        </v-card>
    </v-form>
        <v-row  no-gutters>
            <v-spacer/>
                <v-col cols="auto" class="d-flex mt-2" align="right">
                    <v-btn :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                      <v-btn class="ml-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="onClose">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
        </v-row>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
// import CreateMultiParam from '@/components/CreateMultiParam.vue'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
import WorkspaceMachineParamForm from '@/components/forms/WorkspaceMachineParamForm';

// import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
    name:'AddShiftBasedConsumptionTableWidget',
    props:['widget','seq','disableTimeframe'],
    components:{
        InfoAlert,
        WorkspaceMachineParamForm,
        // CreateMultiParam
        // DeleteConfirmation,
       // ParamEquation
       
    },
    mounted(){
        this.init()
        this.getParamEquations()
    },
    data(){
        return {
            paramEquations:[],
            showDialog:false,
            showSingleParam:false,
            showMultiParam:false,
            counter:0,
            count:0,
            show:false,
            dialog:false,
            loading:false,
            valid:false,
            widgetValid:false,
            showDismissibleAlert:false,
            info:'',
            api:'createWidgetForView',
            edit:false,
            paramType:'param',
            //workspace:null,
            //machine:null,
            legend:false,
            textInfo:null,
            tableData:[],
            paramTemp:null,
            operatorTemp:null,
            paramArray:[],
            // paramSingleFinal:[],
            paramMultiFinal:[],
            equation:'',
            form:{
                            multiplier: 1, 
            },
            widgetForm:{},
            machineOptions:[],
            paramOptions:[],
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'3/4 screen', value:9},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            tdOptions:[
                {label:'Current Day', value:'0'},
                {label:'yesterday', value:'1'},
                {label:'2 days Ago', value:'2'},
                {label:'3 days Ago', value:'3'},
                {label:'7 days Ago', value:'7'},
                {label:'30 days Ago', value:'30'},
            ],
            rules:FormRules.rules,
            objectRules:[
                v => !!v || 'Required',
            ],
            numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
             countRules:[

                v => !!v || 'Required',

                v => (v && v>1) || 'Number of Machine should be greater than 1'

            ],
            
        }
    },
    computed:{
        // workspaceOptions(){
        //     let op=[]
          
        //       let g=this.$store.state.workspaces
        //       for(let i of g){
        //           //op.push({value:i, text: i.name })
        //           op.push( {value:i.workspace_id, label: i.name })
        //         }
        //   return op
        // },
        
        
 paramEquationOptions(){
        let op=[]
        
        for(let i of this.paramEquations){
            op.push( {value:i.eq_id, label: i.label })
        }
        return op
         },
 shiftOptions(){
        let op=[]
        
        for(let i of this.$store.state.shifts){
            op.push( {value:i.shift_id, label: i.name })
        }
        return op
         },

        
    },
    methods:{
        updateParam(param){
            // console.log(param)
            if(param['parameter'] && param['parameter'].param_id){

                this.form.id=param['parameter'].param_id
            }
        },
            getParamEquations(){
        this.loading=true
            axios.get(this.$store.state.api+'paramEquations',{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.paramEquations=response.data.data
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })


      },
        init(){
            if(this.widget && this.widget.widget_id && this.widget.view_id){
                   console.log(this.widget,"widget")
            this.edit=true
            this.widgetForm=Object.assign({},this.widget)
            this.api='updateWidget'
            if(!(this.widgetForm.meta)){
                this.widgetForm.meta={}
                this.form={}
            }else{
                this.form=this.widgetForm.meta
               // this.workspace = this.widgetForm.meta.workspace
                //this.machine = this.widgetForm.meta.machine
                this.paramType=this.widgetForm.meta.param_type
              this.form.id= this.widgetForm.meta.id;
                // this.legend=this.widgetForm.meta.legend
                // this.textInfo=this.widgetForm.meta.textinfo
            }

        }else{
            if(this.widget.view_id){
                this.api='createWidgetForView'
                this.widgetForm=Object.assign({},this.widget)
                this.widgetForm.meta={}
                this.widgetForm.seq=this.seq?this.seq:0
                this.form={}
            }
        }
        },

 
        onReset(){
            this.showSingleParam=false
            this.showMultiParam=false
            this.show=false,
            this.dialog=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.paramTemp=null
            this.operatorTemp=null
            this.equation=''
            this.form.no_ofparams= null,
            this.paramNegFinal=[],
            this.paramPosFinal=[],
            this.paramArray=[],
            this.paramPos=[],
            this.paramNeg=[],
            this.operatorArray=[],
            
            this.paramMultiFinal=[],
            this.$refs.usageFormMulti.reset()
            //this.dialog=false
            this.loading=false
            //this.$emit('close')
        },
        onSubmit(){
            this.$refs.widgetForm.validate()
            if(this.widgetValid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
           // this.form.workspace = this.workspace
            //this.form.machine = this.machine
            this.widgetForm.meta.id = this.form.id;
            this.widgetForm.meta=this.form
            this.widgetForm.meta.param_type=this.paramType
            console.log(this.widgetForm.meta,"form")
            // this.widgetForm.meta.legend=this.legend
            // this.widgetForm.meta.textinfo=this.textInfo
            axios.post(this.$store.state.api+this.api,this.widgetForm,{headers: { 
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
   
    .then(response=>{
      if(response.data.status==='success'){
            this.loading=false
            //this.dialog=false
            this.$emit('success')
            this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
            this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
            
        },
        onClose(){
            this.show=false,
            this.dialog=false,
            this.counter=0
            this.workspace=null
            this.machine=null
            this.form.label=null
            this.form={}
            this.$refs.widgetForm.reset()
            this.loading=false
            this.$emit('close')
        },

        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }

    },
    watch:{
        // workspace(){
        //     this.getMachineList()
        // },
        // machine(){
        //     this.getParams()
        // },
        widget(){
            this.init()
        }

    }
}
</script>