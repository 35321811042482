<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row no-gutters class="pb-1">
            <v-col>
                <h3>{{ graph_label?graph_label:"TOD Billing Graph" }}</h3>
            </v-col>
            <v-col  align="right">
                <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory rounded>
                    <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn>
                    <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-divider/>
        <div  ref="vis"></div>
        <div v-if="!(dbData && dbData.length>0)" :style="[{'display':'flex','justify-content':'center'}]">
            <v-icon class="mr-3">mdi-database-remove-outline</v-icon><h3>No data to show</h3>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import Plotly from 'plotly.js'
import moment from 'moment'

export default {
    name: 'TODBillingBarGraph',
    props: ['machines', 'from_date','to_date','background_color','graph_label'],
    components: {
        InfoAlert
    },
    data() {
        return {
            loading: false,
            valid: false,
            showDismissibleAlert: false,
            info: '',
            dbData: [],
            graphData: {},
            graphType:0,
            layout : {
                barmode: 'group',
                font:{
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                plot_bgcolor:this.background_color?this.background_color:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.background_color?this.background_color:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:true,
                autosize: true,
                margin: {
                    t: 10,
                    pad: 0
                },
                xaxis: {
                    title:"Date",
                    tickangle: -12,
                    zeroline: true,
                    showgrid: true,
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },       
                yaxis: {
                    zeroline: true,
                    title:"Amount",
                    showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode,
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        createGraph() {
            this.graphData={}
            for (let i of this.dbData) {
                if(!(i.label in this.graphData)){
                    let type=this.graphType==1?'line':'bar'
                    this.graphData[i.label]={
                        x: [],
                        y: [],
                        name:i.label,
                        type: type,
                        opacity:1,
                        marker: {
                            line: {
                                color: this.$store.getters.getColorPalette().accentCode,
                                width: 1
                            },
                        }
                    }
                }
                this.graphData[i.label]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
                this.graphData[i.label]['y'].push(i.tariff_amount)
            }
            Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, { displaylogo: false });
        },
        getData(){
            if(this.machines && this.machines.length>0){
                this.loading=true
                let labelMap={}
                let payload={
                    from_date:this.from_date,
                    groupByDate:true
                }
                let temp=[]
                for(let i of this.machines){
                    labelMap[i.machine_id]=i.machine_name
                    temp.push(i.machine_id)
                }
                payload['machines']=temp
                if(this.to_date){
                    payload['to_date']=this.to_date
                }
                axios.post(this.$store.state.api+'getTotalMachineTariffDailyDataBetweenDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.dbData=response.data.data
                        this.dbData.map(x=>{
                            x.label=labelMap[x.machine_id]?labelMap[x.machine_id]:'Label Not defined'
                        })
                        this.dialog=false
                        this.loading=false
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                })
                .catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                });
            }
        },
    },
    watch: {
        dbData() {
            if (this.dbData && this.dbData.length > 0) {
                this.createGraph();
            }
        },
        machines() {
            this.getData();
        },
        from_date() {
            this.getData();
        },
        to_date() {
            this.getData();
        },
        graphType() {
            if (this.dbData && this.dbData.length > 0) {
                this.createGraph();
            }
        },
    }
}
</script>
