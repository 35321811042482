<template>
    <v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="viewForm"
            v-model="valid"    
          >
        <v-row >
            <v-col v-if="!editMode">
                <v-select
                v-if="!loading"
              v-model="workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          filled
          item-text="label"
          item-value="value"
          required
              :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
<v-col>
    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                       :color="$store.getters.getColorPalette().inputtextColor"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="form.default_width"
                        :items="widthOptions"
                        :label="$store.getters.getTextMap().widget_width"
                        
                        
                        item-text="label"
                        item-value="value"
                        ></v-select>  
</v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.msg"
                        :counter="55"
                        :rules="msgRules"
                        :label="$store.getters.getTextMap().description"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.process_seq"
                        :counter="4"
                        :label="$store.getters.getTextMap().sequence"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        <v-col>
                    <v-select
                        v-if="!loading"
                        v-model="form.tariff_id"
                        :items="tariffOptions"
                        :label="$store.getters.getTextMap().tariffs"
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        required
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
        <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.data_source"
          :items="data_sources"
          :label="$store.getters.getTextMap().machine_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                </v-col>
        </v-row>
        <!-- <v-divider :style="{'margin-top':'5%'}"></v-divider> -->
        <v-row v-if="form.data_source==='virtual'">
            <v-col>
                <!-- <h2 :style="{'margin-bottom':'5%','margin-top':'5%'}">Add Positive And Negative Machines To List</h2> -->
            <v-row>
                <v-col>
                    <v-select
                        v-if="!loading"
                        v-model="positiveWorkspace"
                        :items="positiveWorkspaceOptions"
                        :label="$store.getters.getTextMap().positive_workspace"
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        required
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                       :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().inputtextColor"
                      
                       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="positive_machine_list"
                        :items="positiveMachineOptions"
                        multiple
                        :label="$store.getters.getTextMap().positive_machine"
                        outlined
                        item-text="label"
                        item-value="value"
                        required
                        :rules="objectRules"
                    ></v-select>
                </v-col> 
                <!-- <v-col align="center">
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        color="blue lighten-1"
                     
                        @click="addPositiveMachineToList"
                    >
                        Add Positive Machine To List
                    </v-btn>
                </v-col> -->
                <v-col>
                    <v-select
                        v-if="!loading"
                        v-model="negativeWorkspace"
                        :items="negativeWorkspaceOptions"
                        :label="$store.getters.getTextMap().negative_workspace"
                        dense
                        filled
                        item-text="label"
                        item-value="value"
                        required
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        v-if="!loading"
                        v-model="negative_machine_list"
                        :items="negativeMachineOptions"
                        multiple
                        :label="$store.getters.getTextMap().negative_machine"
                        outlined
                        item-text="label"
                        item-value="value"
                        required
                        :rules="objectRules"
                        ></v-select>
                </v-col> 
                <!-- <v-col align="center">
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        color="blue lighten-1"
                     
                        @click="addNegativeMachineToList"
                    >
                        Add Negative Machine To List
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="my-2"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" elevation="3">
                        <v-row  v-if="!loading" style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong>{{ $store.getters.getTextMap().label }}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{ $store.getters.getTextMap().positive_machine_Ids }}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in positive_machine_list"
                                            >
                                            <td>{{ item.machine_name }}</td>
                                            <td>{{ item.machine_id}}</td>
                                            <td>
                                            <v-icon @click="deleteFromPositiveMachineTable(item)" small color="red" >mdi-delete</v-icon>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>  
            </v-col> 
            <v-col>
                    <v-card class="my-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" elevation="3">
                        <v-row  v-if="!loading" style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}">
                                    <template v-slot:default>
                                        <thead>
                                            <tr> 
                                                <th class="text-left">
                                                    <strong>{{ $store.getters.getTextMap().label }}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong>{{ $store.getters.getTextMap().negative_machine_Ids }}</strong>
                                                </th> 
                                            </tr>  
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in negative_machine_list"
                                            >
                                            <td>{{ item.machine_name }}</td>
                                            <td>{{ item.machine_id }}</td>
                                            
                                            <td>
                                            <v-icon @click="deleteFromNegativeMachineTable(item)" small color="red" >mdi-delete</v-icon>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>  
            </v-col> 
        </v-row>
    </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        :color="$store.getters.getColorPalette().submitbtnColor"
                     
                        @click="onSubmit"
                    >
                    {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                       :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    </v-container>
            
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import EntureStaticLists from '@/utillities/EntureStaticLists'
import axios from 'axios'
export default {
    name:'CreateMachine',
    props:['machine'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.machine && this.machine.machine_id){
            // console.log(this.machine)
            this.api='updateMachine'
            this.editMode=true
            this.form=Object.assign({},this.machine)
            this.workspace=this.form.workspace_id
            if(this.form.data_source){
                if(this.form.positive){

                    for(let i of this.form.positive){
                        this.positive_machine_list.push( JSON.parse(i))
                    }
                }
                if(this.form.negative){

                    for(let i of this.form.negative){
                        this.negative_machine_list.push( JSON.parse(i))
                    }
                }
            }
        }else{
            this.api='createMachine'
            this.editMode=false
        }

    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            api:'createMachine',
            editMode:false,
            positive_machine_list:[],
            negative_machine_list:[],
            workspace:null,
            negativeWorkspace:null,
            positiveWorkspace:null,
            machineOptions:null,
            negativeMachineOptions:null,
            positiveMachineOptions:null,
            data_sources:[{label:this.$store.getters.getTextMap().physical_machine,value:'physical'},
            {label:this.$store.getters.getTextMap().virtual_machine,value:'virtual'},],
            form:{
                name:null,
                // view_type:'tableView',
                data:{},
                data_source:'physical',
                process_seq:0,
                tariff_id:null,
            },
            widthOptions:EntureStaticLists.widthOptions,
            objectRules:[
                v => !!v || this.$store.getters.getTextMap().required,
            ],
            nameRules: [
                v => !!v || this.$store.getters.getTextMap().name_is_required,
                v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
                v => (v && v.length <= 25 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_25_characters,
            ],
            msgRules: [
                v => !!v || this.$store.getters.getTextMap().description_is_required,
                v => (v && v.length <= 55 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_55_characters,
            ],
            selectRules:[
                v=> (v!=null) || this.$store.getters.getTextMap().required
            ],
        }
    },
    computed:{
        tariffOptions(){
            let op=[]
          
              let g=this.$store.state.tariffs
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.tariff_id, label: i.name })
                }
          return op
        },
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        positiveWorkspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
        negativeWorkspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i.workspace_id, label: i.name })
                }
          return op
        },
    },
    methods:{
        getMachineList(){
        let op=[]
        if(this.workspace){
            let payload={
                workspace_id:this.workspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
                if(machines){
                    for(let i of machines){
                if(i && i.name){
                    op.push({value:{machine_id:i.machine_id,machine_name: i.name}, label: i.name })
                }else{
                    op.push({value:{machine_id:i.machine_id,machine_name: i.name}, label: i.machine_id })
                }
            }

                }
            
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.machineOptions=op
        },
        getNegativeMachineList(){
        let op=[]
        if(this.negativeWorkspace){
            let payload={
                workspace_id:this.negativeWorkspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:{machine_id:i.machine_id,machine_name: i.name}, label: i.name })
                }else{
                    op.push({value:{machine_id:i.machine_id,machine_name: i.name}, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.negativeMachineOptions=op
        },
        getPositiveMachineList(){
        let op=[]
        if(this.positiveWorkspace){
            let payload={
                workspace_id:this.positiveWorkspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:{machine_id:i.machine_id,machine_name: i.name}, label: i.name })
                }else{
                    op.push({value:{machine_id:i.machine_id,machine_name: i.name}, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.positiveMachineOptions=op
        },
        updateMachineOptions(machine){
            if(machine==='positive_machine'){
                this.machineOptions=this.machineOptions.filter(x=>!this.positive_machine_list.some(z=>x['value']['machine_id']===z['machine_id']))
            }else{
                this.machineOptions=this.machineOptions.filter(x=>!this.negative_machine_list.some(z=>x['value']['machine_id']===z['machine_id']))
            }
        },
        deleteFromPositiveMachineTable(item){
            this.positive_machine_list=this.positive_machine_list.filter(x=>x.machine_id!=item.machine_id)
            this.machineOptions.push({value:{machine_id:item['machine_id'],machine_name:item['machine_name']},label:item['machine_name']})
            },
        deleteFromNegativeMachineTable(item){
            this.negative_machine_list=this.negative_machine_list.filter(x=>x.machine_id!=item.machine_id)
            this.machineOptions.push({value:{machine_id:item['machine_id'],machine_name:item['machine_name']},label:item['machine_name']})
            },
        onSubmit(){
            this.$refs.viewForm.validate()
            if(this.valid){
                 this.loading=true
                 this.form.positive=[]
                 this.form.negative=[]
                 this.form.workspace_id = this.workspace
                 if(this.form.data_source==='virtual'){
                     this.form.positive=this.positive_machine_list
                     this.form.negative=this.negative_machine_list
                 }
                let d=Object.assign({}, this.form)
                //console.log(d)
                d['data']={headers:[],params:[]}
                if(this.editMode){
                    d['machine_id']=this.machine.machine_id
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.$refs.viewForm.reset()
                            this.$emit('machineUpdate')
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        negativeWorkspace(){
            this.getNegativeMachineList()
        },
        positiveWorkspace(){
            this.getPositiveMachineList()
        },
        // positive_machine_list(){
        //     this.updateMachineOptions('positive_machine')
        // },
        // negative_machine_list(){
        //     this.updateMachineOptions('negative_machine')
        // }
    }
}
</script>