<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
          <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-list-item @click="update=true">
                <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="deleteWidget">
                <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                <v-list-item-icon>
                   
                  <v-icon color="red" small>mdi-trash-can</v-icon>

             
                </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
              <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{widget.label}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn :dark="$store.getters.getColorPalette().isDark"  text @click="update = false">{{$store.getters.getTextMap().close}}</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <AddPieTDWidget :widget="widget" :disableTimeframe="disableTimeframe" v-on:close="update=false" v-on:success="$emit('update')" />
          </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
          <div v-if="widget.meta.data" ref="vis" :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{}]"></div>
          <div v-else :style="[widget.height==='fixed' ? {'height':'175px','text-align':'center'}:{'text-align':'center'}]">
            <v-icon  small>mdi-hail</v-icon>
            <h5 >{{$store.getters.getTextMap().not_configured}}</h5>
          </div>
    </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddPieTDWidget from '@/components/crud_components/widgets/AddPieTDWidget'

export default {
    name:'PieTDWidget',
    props:['widget','filter','edit','disableTimeframe','timeframe'],
    components:{
        AddPieTDWidget,
        InfoAlert,
        // DeleteConfirmation
    },
    data(){
        return {
          showDialog:false,
            x:0,
            y:0,
            showMenu:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            update:false,
            graphData:{},
            positive:0,
            negative:0,
            count:0,
            // colorCode:this.$store.getters.getColorPalette().gradientColorCodes,
            layout : {
                      //height: 300,
                      //width: 500,
                      // title:{
                        font:{
                          color:this.$store.getters.getColorPalette().accentCode
                        },
                      // }
                      plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode,
                      paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                      showlegend:false,
                      margin: {
                          b: 2, // top margin
                          pad: 0 // padding
                      },
                      legend: {
                        orientation: "h",
                        bgcolor: ' #00000000',
                        font: {
                          size: 8,
                        },
                        // x: 0.5,  // Center the legend horizontally
                        // y: -0.1, // Position legend below the plot area
                        xanchor: 'right',
                        yanchor: 'top',
                      },
                      autosize: true,
                      xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                      },       
                      yaxis: {
                        showticklabels: true
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
            }
    },
    mounted(){
        if(this.widget && this.widget.meta && this.widget.meta.data){

            this.getData()
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
      },
      createChart(graphData){
        // console.log(graphData)
            this.layout['title']= this.widget.label
          
            let textInfo='label+percent'
            if(this.widget.meta && this.widget.meta.legend){
              this.layout['showlegend']=true
              
              textInfo='percent'
            }
            if(this.widget.meta && this.widget.meta.textinfo){
              textInfo=this.widget.meta.textinfo
            }
            
            this.layout['annotations']=[{
                    font: {
                        size: 22
                    },
                    showarrow: false,
                    text: this.widget.meta.pie?'':(this.widget.meta.time_frame?this.widget.meta.time_frame.toUpperCase():''),
                    x: 0.5,
                    y: 0.5
                    }]
            var data = [{
            values: Object.values(graphData),
            labels: Object.keys(graphData),
            type: 'pie',
            text:this.widget.meta.pie?null:'mtd',
            textinfo: textInfo,
            textposition: "outside",
            opacity:1,
            hole:this.widget.meta.pie?0:0.7,
            marker: {

              line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                },
            }
            //automargin: true
            }];
            // console.log(data)
            if(this.$refs.vis){
              Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
            }
           
        },
        async getData(){
          try{
            if(this.widget.height==='fixed'){
              this.layout.height=170
              this.layout.font={size:8,color:this.$store.getters.getColorPalette().accentCode}
              this.layout.margin={
                t:40,
                b:40,
              }
          }
          this.layout.colorway = this.colorCode
          this.graphData={}
          let temp={}
          let tf=null
          if(this.timeframe && this.disableTimeframe){
            tf=this.timeframe
          }else{
            tf=this.widget.meta && this.widget.meta.time_frame?this.widget.meta.time_frame:'month'
          }
          let from_date = moment().subtract(1, 'days');
          switch (tf) {
            case '7':
              from_date = moment().subtract(7,'days').format('YYYYMMDD');
              break;
            case '30':
              from_date = moment().subtract(30,'days').format('YYYYMMDD');
              break;
            case '60':
              from_date = moment().subtract(60,'days').format('YYYYMMDD');
              break;
            case '90':
              from_date = moment().subtract(90,'days').format('YYYYMMDD');
              break;
            case '180':
              from_date = moment().subtract(180,'days').format('YYYYMMDD');
              break;
            case '365':
              from_date = moment().subtract(365,'days').format('YYYYMMDD');
              break;
            case 'yesterday':
            from_date = moment().subtract(1,'days').format('YYYYMMDD');
              break;
            case 'ytd':
              from_date = moment().startOf('year').format('YYYYMMDD');
              break;

            default:
              from_date = moment().subtract(1, 'days').startOf('month').format('YYYYMMDD');
              break;
          }
          if(this.widget && this.widget.meta && this.widget.meta.data){
            for(let i of this.widget.meta.data){
              let count = 0
              let positive = 0
              let negative = 0
              let typeoff =typeof(i.params[0])
              if(i.params.length>1 && typeoff==='object'){
                count=0
                for(let j of i.params){
                  if(j.length>0){
                    let payload={
                      parameters:j,
                      from_date:from_date
                    }
                    let response1 = await axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                    if(response1.data.status==='success'){
                      if (count===0) {
                        if(j.length>0){
                          positive = Number(response1.data.data['usage'])
                        }else{
                          positive=0
                        }
                      }else if(count===1){
                        if(j.length>0){
                          negative = Number(response1.data.data['usage'])
                        }else{
                          negative=0
                        }
                      }
                      temp[i.label]= positive - negative
                      // this.createChart()
                      count = count+1
                    }else{
                        this.info=response1.data.msg
                        this.showDismissibleAlert=true
                    }
                    this.loading=false
                    }
                  }
                }else{
                  let payload={
                      parameters:i.params,
                      from_date:from_date
                  }
                  let response2 = await axios.post(this.$store.state.api+'getTotalParamUsage',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                  if(response2.data.status==='success'){
                    temp[i.label]=Number(response2.data.data['usage'])
                      // this.createChart()
                  }else{
                    this.info=response2.data.msg
                    this.showDismissibleAlert=true
                  }
                    this.loading=false
                  }
                }
                this.createChart(temp)
            }
          }catch(error){
            console.error(error)
            this.loading=false;  
          }
        },
        deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    watch:{
        widget:{
            handler(){
                this.getData()
            },
            deep:true
        },
        timeframe:{
          handler(){
                this.getData()
            },
            deep:true
        },
        // graphData(){
        //     if(this.graphData && (Object.values(this.graphData)).length>0 && (Object.keys(this.graphData)).length>0){
        //       this.createChart()
        //     }
        // }
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
</style>