<template>
  
    <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
      <v-card-text>
        <!-- {{ meta }} {{ labelType }} {{ statusRules }} -->
        <v-container fluid>
          <v-row>
            <v-col>
              <v-radio-group
      v-model="labelType"
      mandatory row
    >
      <v-radio
      :label="$store.getters.getTextMap().text"
        value="text"
      ></v-radio>
      <v-radio
      :label="$store.getters.getTextMap().real_time_value"
        value="rtd"
      ></v-radio>
      <v-radio
      :label="$store.getters.getTextMap().real_time_consumption"
        value="rtc"
      ></v-radio>
      <v-radio
      v-if="$store.getters.getAccessByLevels(['alphaaccess'])"
      :label="$store.getters.getTextMap().usage"
        value="usage"
      ></v-radio>
    </v-radio-group>
            </v-col>
          </v-row>
          <v-form ref="statusForm" v-model="valid">
            <v-row no-gutters v-if="labelType!='text'">
              <v-col align-self="center" align="left">
              <h3 :color="$store.getters.getColorPalette().accentCode" >{{$store.getters.getTextMap().choose_parameter}}</h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align="right" align-self="center"  >
              <v-radio-group v-model="formType" row :dark="$store.getters.getColorPalette().isDark">
                <v-radio :label="$store.getters.getTextMap().machine" value="machineForm"></v-radio>
                <v-radio :label="$store.getters.getTextMap().device" value="deviceForm"></v-radio>
              </v-radio-group>
            </v-col>
        </v-row>
          <v-row v-if="labelType!='text'">
            <v-col>
              <v-select
            v-model="workspace"
        :items="workspaceOptions"
        :label="$store.getters.getTextMap().workspace"
        outlined
        dense
        item-text="label"
        item-value="value"
        required
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          
      ></v-select>
          </v-col>
          <v-col>
            
              <v-select
              v-if="formType=='deviceForm'"
            v-model="form.device"
        :items="deviceOptions"
        :label="$store.getters.getTextMap().device"
        outlined
        dense
        item-text="label"
        item-value="value"
            :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        required
      ></v-select>
      <v-autocomplete
                    v-else
            v-model="form.machine"
            :items="machineOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().machine"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
          </v-col>
          <v-col>
              <v-autocomplete
            v-model="form.param_id"
        :items="paramOptions"
        :label="$store.getters.getTextMap().device_parameter"
        outlined
        item-text="label"
        item-value="value"        
        :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        required
        dense
          :rules="objectRules"
      ></v-autocomplete>
          </v-col>
          </v-row>
          <h3 v-if="labelType!='text' && labelType!='usage'">{{$store.getters.getTextMap().default_status}}
</h3>
         
          <v-row>
            <v-col>
              <v-text-field
                        v-if="!loading"
                        v-model="form.label"
                        :rules="objectRules"
                        :label="$store.getters.getTextMap().default_label"
                        dense
                        outlined
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                    v-model="form.color"
                    :items="colorOptions"
                    required
                    :label="$store.getters.getTextMap().default_color_optional"
          clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    
                  >
                  <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                
                </v-select>
            </v-col>
          </v-row>
          <v-row no-gutter v-if="labelType=='usage'">
            <v-col>
              <v-select :dark="$store.getters.getColorPalette().isDark"
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{ dark: $store.getters.getColorPalette().isDark }" v-if="!loading"
                            v-model="form.usage_timeframe" :items="tdOptions" :label="$store.getters.getTextMap().timeFrame" dense outlined item-text="label"
                            item-value="value"></v-select>
            </v-col>
          </v-row>
          <v-row no-gutter v-if="labelType!='text'">
            <v-col><v-checkbox
      v-model="form.ruleBased"
      :label="$store.getters.getTextMap().rule_based_status"
    ></v-checkbox></v-col>
            <v-col><v-checkbox
      v-model="form.showLabel"
      :label="$store.getters.getTextMap().show_label_only"
    ></v-checkbox></v-col>
            <v-col><v-checkbox
      v-model="form.showUnit"
      :label="$store.getters.getTextMap().show_unit"
    ></v-checkbox></v-col>
          </v-row>
          </v-form>
          
          <v-form ref="ruleForm" v-model="ruleValid" v-if="labelType!='text' && form.ruleBased">
          <v-row>
            <v-col>
              <h3> {{$store.getters.getTextMap().status_rules}}
</h3>
              
              <v-divider></v-divider>
              <v-simple-table
    fixed-header
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{$store.getters.getTextMap().label}}

          </th>
          <th class="text-left">
            {{$store.getters.getTextMap().color}}

          </th>
          <th class="text-left">
            {{$store.getters.getTextMap().operator}}

          </th>
          <th class="text-left">
            {{$store.getters.getTextMap().value}}

          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(rule,index) in statusRules"
          :key="index"
        >
          <td>{{ rule.label }}</td>
          <td><div :style="{'background-color':rule.color}">{{ rule.color }}</div></td>
          <td>{{ rule.operator }}</td>
          <td>{{ rule.operand }}</td>
          <td><v-icon @click="moveUp(index)" :disabled="index === 0" color="blue">mdi-arrow-up-thin</v-icon>
       <v-icon @click="moveDown(index)" :disabled="index === statusRules.length - 1" color="blue">mdi-arrow-down-thin</v-icon><v-icon @click="removeRule(index)" color="red">mdi-delete</v-icon></td>
        </tr>
        <tr>
          <td><v-text-field
                        v-if="!loading"
                        v-model="ruleForm.label"
                        :rules="objectRules"
                        :label="$store.getters.getTextMap().label"
                        dense
                        outlined
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field></td>
          <td>
            <v-select
                    v-model="ruleForm.color"
                    :items="colorOptions"
                    required
          clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    outlined
                    dense
                    :rules="objectRules"
                  >
                  <template v-slot:selection="{ item }">
                    <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                  <div :style="{'background-color':item.value}">{{ item.label }}</div>
                  </template>
                
                </v-select>
          </td>
          <td>
            <v-autocomplete
      v-model="ruleForm.operator"
      :items="operatorOptions"
      required
      clearable
      item-text="label"
      item-value="value"
      :menu-props="{dark: $store.getters.getColorPalette().isDark}"
      outlined
      dense
      :rules="objectRules"
    ></v-autocomplete>
  </td>
  <td>
    <v-text-field
                        v-if="!loading"
                        v-model="ruleForm.operand"
                        :rules="[rules.required,rules.noSpace,rules.num]"
                        :label="$store.getters.getTextMap().value"
                        dense
                        outlined
                        required
                       :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
  </td>
          <td><v-btn fab small @click="addRule"><v-icon  color="blue">mdi-plus</v-icon></v-btn></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
              
              <v-divider></v-divider>
              
              <!-- <v-checkbox v-model="showLabels" label="Show Label"></v-checkbox> -->
             
            </v-col>
          </v-row>
        </v-form>
        <v-row>
              <v-col>
    <v-btn @click="submitForm" color="primary">{{$store.getters.getTextMap().submit}}
</v-btn>
    </v-col>
    <v-col>
    <v-btn @click="cancelForm" color="error">{{$store.getters.getTextMap().cancel}}
</v-btn>
  </v-col>
</v-row>
        </v-container>
         
      </v-card-text>
    </v-card>
  
</template>

<script>
import EntureStaticLists from '@/utillities/EntureStaticLists'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
  name: 'RealtimeStatusForm',
  props:['meta'],
  data() {
    return {
      valid:false,
      ruleValid:false,
      loading:false,
      workspace:null,
      formType:'machineForm',
      form:{},
      ruleForm:{},
      showLabels: false,
      statusRules:[],
      payload:{},
      labelType:null,
      machineOptions:[],
      operatorOptions:EntureStaticLists.comparisonOperators,
      colorOptions: EntureStaticLists.colorOptions,
      tdOptions:EntureStaticLists.tdOptions,
      rules:FormRules.rules,
      objectRules: [
        FormRules.rules.required
        ],
    }
  },
  mounted(){
    this.init()
  },
  computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
             
            let op=[]
          
          if(this.workspace && this.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.snode_id })
                  }
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.workspace && this.formType){
            let g=[]
            if(this.formType=='deviceForm' && this.form.device && this.form.device.device_id ){
              g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
            }else if(this.formType=='machineForm' && this.form.machine && this.form.machine.machine_id){
              g=this.$store.getters.getParamsByMachineID(this.form.machine.machine_id)
            }else{
              return op
            }
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
        
    },
  methods: {
    getMachineList(){
        let op=[]
        if(this.workspace){
            let payload={
                workspace_id:this.workspace.workspace_id
            }
            this.loading = true;
        axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                op.push({value:{machine_id:i.machine_id,machine_name:i.name,workspace_id:this.workspace.workspace_id}, label: i.name })
                }else{
                op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            this.machineOptions=op
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
    },
    init(){
      if(this.meta && this.meta.label){
        this.form={...this.meta}
        this.payload={...this.meta}
        if(this.meta && this.meta.statusRules){
          this.statusRules=[...this.meta.statusRules]

        }
        if(this.meta.type){
          this.labelType=this.meta.type
        }
      }

    },
    moveUp(index) {
   if (index > 0) {
    //   console.log('Before move up:', this.views.map(view => view.seq));
     const temp = this.statusRules[index];
     this.$set(this.statusRules, index, this.statusRules[index - 1]);
     this.$set(this.statusRules, index - 1, temp);
    //   console.log('After move up:', this.views.map(view => view.seq));
   }
 },
 moveDown(index) {
   if (index < this.statusRules.length - 1) {
    //  console.log('Before move down:', this.views.map(view => view.seq));
     const temp = this.statusRules[index];
     this.$set(this.statusRules, index, this.statusRules[index + 1]);
     this.$set(this.statusRules, index + 1, temp);
    //  console.log('After move down:', this.views.map(view => view.seq));
   }
 },
  
    addRule() {
      this.$refs.ruleForm.validate()
      if(this.ruleValid){
        this.statusRules.push({
          label: this.ruleForm['label'],
          color: this.ruleForm['color'],
          operator: this.ruleForm['operator'],
          operand: Number(this.ruleForm['operand']),
          // seq:this.statusRules.length,
          // showRemoveButton: true
        });
      }
    },


    removeRule(index) {
      if (index !== -1) {
        this.statusRules.splice(index, 1); 
      }
    },
    
  submitForm() {
    this.$refs.statusForm.validate()
            if(this.valid){
              this.payload={
                ...this.payload,
                param_id:this.form.param_id,
                color:this.form.color,
                label:this.form.label,
                type:this.labelType,
                showLabel:this.form.showLabel,
                showUnit:this.form.showUnit,
                ruleBased:this.form.ruleBased,
                statusRules:this.statusRules,

              }
              console.log(this.payload)
              this.$emit('value', this.payload);
              this.$emit('close');
            }
},


cancelForm() {
  this.$emit('close');
    }
  },
  watch:{
    meta:{
        handler(){
            this.init()
        },
        deep:true
    },
    workspace(){
      if(this.formType=='machineForm'){

        this.getMachineList()
      }
    },
    formType(){
      if(this.workspace){
        this.getMachineList()
      }
    },
    machine:{
        handler(){
            this.send()
        },
        deep:true
    }
}
};

</script>

<style>
/* Add any custom styles here */
</style>