<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="workspace"
            :items="workspaceOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            
            :label="$store.getters.getTextMap().workspace"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col>
                <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                    <v-autocomplete
                    v-if="!loading"
            v-model="machine"
            :items="machineOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().machine_name"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
            <v-col v-if="multiple">
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            multiple
            :label="$store.getters.getTextMap().parameters"
            item-text="label"
                    item-value="value"
                    :rules="objectRules"
          ></v-autocomplete>
            </v-col>
            <v-col v-else>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                
                <v-autocomplete
                v-if="!loading"
            v-model="parameter"
            :items="paramOptions"
            :dark="$store.getters.getColorPalette().isDark" 
                    :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            small-chips
            
            :label="$store.getters.getTextMap().parameters"
            item-text="label"
                    item-value="value"
          ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="multiple">
            <v-col>
              
              <v-chip
                class="ma-2"
                close
                small
                color="teal"
                text-color="white"
                @click:close="remove(param)" 
                v-bind:key="param.parameter" v-for="param in parameter"
              >
               {{param.name}}
              </v-chip>
                      </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
name:'WorkspaceMachineParamForm',
props:['multiple','exsistingWorkspace','exsistingMachine','exsistingParameter'],
components:{
    InfoAlert,
},
data(){
    return {
        showDialog:false,
        loading:false,
        info:'',
        showDismissibleAlert:false,
        workspace:null,
        machine:null,
        parameter:null,
        form:{},
        machineOptions:[],
        paramOptions:[],
        objectRules:[
            v => !!v || 'Required',
        ],
    }
},
mounted(){
    if(this.exsistingParameter){
        this.parameter=this.exsistingParameter
    }
    if(this.exsistingWorkspace){
        this.workspace=this.exsistingWorkspace
    }
    if(this.exsistingMachine){
        this.machine=this.exsistingMachine
    }
},
computed:{
    workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
            for(let i of g){
                op.push( {value:{workspace_id:i.workspace_id,workspace_name:i.name}, label: i.name })
            }
        return op
    },
},
methods:{
    remove(param){
      this.parameter=this.parameter.filter(x=>x.param_id!=param.param_id)
    },
    getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    let machines=response.data.machines
                for(let i of machines){
                  if(i && i.name){
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.machine_id })
                  }
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
    getParams(){
        if(this.machine ){
            this.loading=true
            axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine.machine_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response=>{
                if(response.data.status=='success'){
                    this.loading=false
                    this.paramOptions=[]
                    for(let i of response.data.params){
                        this.paramOptions.push({label:i.name,value:{machine_name: this.machine.name,param_id:i.param_id,name:this.machine.name+' - '+i.name, processing: i.processing,meta:i.meta}})
                    }
                }else{
                    this.loading=false
                    this.info=response.data.msg
                    this.showDismissibleAlert=true
                }
            }).catch(err=>{
                this.loading=false
                this.info=err
                this.showDismissibleAlert=true
            })
        }
    },
    send(){
        this.form.parameter=this.parameter
        this.form.machine=this.machine
        this.form.workspace=this.workspace
        this.form.device=this.machine
        this.$emit('value',this.form)
    }
},
watch:{
    workspace(){
        this.getMachineList()
    },
    machine(){
        this.getParams()
    },
    parameter:{
        handler(){
            this.send()
        },
        deep:true
    }
}
}
</script>