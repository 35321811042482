
export default {
    name:'EntureStaticLists',
    operators:[
        {label:'*',value:'*'},
        {label:'^',value:'^'},
        {label:'+', value:'+'},
        {label:'-', value:'-'},
        {label:'/', value:'/'},
    ],
    comparisonOperators:[
        {label:'Greater than', value:'>'},
        {label:'Lesser than', value:'<'},
        {label:'Equals to', value:'=='},
        {label:'Greater than equal to', value:'>='},
        {label:'Lesser than equal to', value:'<='},
        {label:'Modulus Lesser than equal to', value:'|<='},
        {label:'Modulus Greater than equal to', value:'|>='},
        {label:'Modulus Greater than', value:'|>'},
        {label:'Modulus Lesser than', value:'|<'},
    ],
    colorOptions:[
        {label:'Red', value:'#F44336'},
        {label:'Purple', value:'#9C27B0'},
        {label:'Indigo', value:'#3F51B5'},
        {label:'Blue', value:'#2196F3'},
        {label:'Light Blue', value:'#03A9F4'},
        {label:'Teal', value:'#009688'},
        {label:'Green', value:'#4CAF50'},
        {label:'Light Green', value:'#8BC34A'},
        {label:'Lime', value:'#CDDC39'},
        {label:'Yellow', value:'#FFEB3B'},
        {label:'Orange', value:'#FF9800'},
        {label:'Amber', value:'#FFC107'},
        {label:'Cyan', value:'#00BCD4'},
        {label:'White', value:'#FAFAFA'},
        {label:'Black', value:'#000000'},
    ],
    backgroundColorOptions:[
        {label:'Default/Empty', value:null},
        {label:'Amber', value:'#FFCA28'},
        {label:'Azure', value:'#B2EBF2'},
        {label:'Black', value:'#212121'},
        {label:'Biege', value:'#FFE0B2'},
        {label:'Blue', value:'#42A5F5'},
        {label:'Cyan', value:'#26C6DA'},
        {label:'Coral', value:'#F8BBD0'},
        {label:'Green', value:'#66BB6A'},
        {label:'Indigo', value:'#5C6BC0'},
        {label:'Lavender', value:'#E1BEE7'},
        {label:'Light Blue', value:'#29B6F6'},
        {label:'Light Green', value:'#9CCC65'},
        {label:'Lime', value:'#D4E157'},
        {label:'Mint', value:'#C8E6C9'},
        {label:'Orange', value:'#FFA726'},
        {label:'Purple', value:'#AB47BC'},
        {label:'Red', value:'#EF5350'},
        {label:'Teal', value:'#26A69A'},
        {label:'Salmon', value:'#FFCDD2'},
        {label:'White', value:'#FAFAFA'},
        {label:'Yellow', value:'#FFEE58'},
    ],
    paramTypes:[
        { label: 'Computed',value: 'computed'},
        { label: 'Parameter',value: 'parameter'},
    ],
    baudRates:[
        { label: '9600',value: 9600},
        { label: '19200',value: 19200},
    ],
    parityOptions:[
        { label: 'Even',value: 'even'},
        { label: 'Odd',value: 'odd'},
        { label: 'None',value: 'none'},
    ],
    stopBits:[
        { label: '1',value: 1},
        { label: '2',value: 2},
    ],
    widthOptions:[
        {label:'Full screen', value:12},
        {label:'Half screen', value:6},
        {label:'1/3 screen', value:4},
        {label:'Quarter screen', value:3},
        {label:'3/4 screen', value:9},
        {label:'Auto',value:null}
    ],
    dayOptions:[
        {label:'Everyday', value:null},
        {label:'Monday', value:1},
        {label:'Tuesday', value:2},
        {label:'Wednesday', value:3},
        {label:'Thursday', value:4},
        {label:'Friday', value:5},
        {label:'Saturday',value:6},
        {label:'Sunday',value:0},
    ],
    tdOptions: [
        { label: 'Yesterday', value: '1' },
        { label: 'MTD (Month Till Date)', value: 'mtd' },
        { label: 'YTD (Year Till Date)', value: 'ytd' },
        { label: 'last 7 days', value: '7' },
        { label: 'last 30 days', value: '30' },
        { label: 'last 60 days', value: '60' },
        { label: 'last 90 days', value: '90' },
        { label: 'last 180 days', value: '180' },
        { label: 'last 365 days', value: '365' },
    ],
}