<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog=true" v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update=true">
                    <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                    <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                        <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                        <v-list-item-icon>
                            
                            <v-icon color="red" small>mdi-trash-can</v-icon>
                        
                        </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition" >
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"> 
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{widget.label}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false" >{{$store.getters.getTextMap().close}}</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddShiftBasedConsumptionTableWidget :widget="widget" v-on:close="update=false" v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <span><strong>{{ widget.label }}</strong></span>
        <div v-if="tableData && tableData.length>0" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().background2ColorCode,'height':'175px' ,'padding-top':'0px', 'padding-bottom':'0px'}: {'padding-top':'0px','padding-bottom':'0px'}]">
            <v-simple-table dense :height="tableHeight" :style="{background: $store.getters.getColorPalette().background2ColorCode}" >
                <template v-slot:default>
                    <tbody >
                        <tr v-for="item in tableData" :key="item.label" :style="[{'background-color': $store.getters.getColorPalette().background2ColorCode, 'padding-bottom':'-5px'}]">
                            <td><strong>{{item.label}}</strong></td>
                            <td><strong>{{ item.value }}</strong></td>
                            
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            
        </div>
        <div v-else :style="[widget.height==='fixed' ? {'height':'175px'}:{}]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{$store.getters.getTextMap().not_configured}}</h5>
        </div>
    </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import DateUtils from '@/utillities/DateUtils'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddShiftBasedConsumptionTableWidget from '@/components/crud_components/widgets/AddShiftBasedConsumptionTableWidget'
export default {
    name:'UsageScoreCardWidget',
    props:['widget','filter','edit'],
    components:{
        AddShiftBasedConsumptionTableWidget,
        InfoAlert,
        // DeleteConfirmation
    },
    mounted(){
        if(this.widget && this.widget.meta && this.widget.meta.shift_id){
            // console.log('data present usage')
            this.loadShiftData()
            this.configured=true
        }else{
            console.log('Shift not present in widget')
        }
    },
    data(){
        return {
            showDialog:false,
          positive_yesterday:0,
          negative_yesterday:0,
          positive_compare:0,
          negative_compare:0,
          fillArray:[],
          fill:[],
          tableHeight:null,
          percentage:0,
          label:[],
          values:[],
          count:0,
            x:0,
            y:0,
            showMenu:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            update:false,
            data_temp_yesterday:null,
            data_temp_compare:null,
            data:{} ,
            cellValues:[],
            configured:false,
            layout : {
        showlegend:false,
        autosize: true,
        },
        tableData:[],
        shiftData:[],
        headers: [
          {
            text: 'Label',
            align: 'start',
            filterable: false,
            value: 'label',
            width:'10%',
            class: "blue-grey darken-4"
          },
          { text: 'Current Value', value: 'value' ,width:'5%',class: "blue-grey darken-4"},
        //   { text: '%', value: 'percentage',width:'85%',class: "blue-grey darken-4" },
        ],
        }
    },
    computed:{
        footerText(){
            if (this.widget.meta.time_frame==='lw'){
                return '*Compared to Last Week'
            }else{
                return '*Compared to Last Month'
            }
        }
    },
    methods:{
        getTimestamps(from_time, to_time){
            let timeFrame=this.widget.meta.time_frame?this.widget.meta.time_frame:0
            let dt=DateUtils.getFromDate(timeFrame)
            let from=moment(dt.format('YYYY-MM-DD')+ ' '+from_time,'YYYY-MM-DD HH:mm')
            let to=moment(dt.format('YYYY-MM-DD')+ ' '+to_time,'YYYY-MM-DD HH:mm')
            // console.log(from,to)
            return [from.unix(),to.unix()]
        },
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
      },
      getColor(color,values){
        if(color.type==='GPRN'){
                if(values>0){
                    return '#66BB6AF1'
                }else{
                    return '#EF5350F1'
            }
        }
        if(color.type==='RPGN'){
                if(values>0){
                    return '#EF5350F1'
                }else{
                   return '#66BB6AF1' 
            }
        }
      },
      async loadShiftData(){
        if(this.widget && this.widget.meta && this.widget.meta.shift_id){

            try{
                this.loading=true
                let payload={
                    shift_id:this.widget.meta.shift_id
                }
                this.tableData=[]
                
                let response=await axios.post(this.$store.state.api+'shiftData',payload,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        // console.log(response.data)
                if(response.data.status==='success'){
                            this.shiftData=response.data.data
                            for(let i of this.shiftData){
                                let from,to;
                                [from,to]=this.getTimestamps(i.from_time,i.to_time)
                                try{

                                    let value = await this.getParamData(this.widget.meta.id,from,to)
                                    let temp={
                                        label:i.name?i.name:i.from_time,
                                        value:value['total']
                                    }
                                    this.tableData.push(temp)
                                }catch(err){
                                    console.error(err)
                                }
                            }
                            
                    }else{
                            this.info="Unable to load shift Data"
                            
                            }
                            this.loading=false
            }catch(err){
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        }
        
        }
            return false
        


      },
      async getParamData(param_id, from_timestamp, to_timestamp){
        try{
                this.loading=true
                let payload={
                    param_id:param_id,
                    from_timestamp:from_timestamp,
                    to_timestamp:to_timestamp
                }
                
                let response=await axios.post(this.$store.state.api+'getConsumptionDataByParamBetweenTimestamp',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                if(response.data.status==='success'){
                    return response.data.data
                    // return true
                }else{
                    this.info="Unable to load param Data"
                    
                }
                this.loading=false
            }catch(err){
            this.loading=false
            this.info=err
            this.showDismissibleAlert=true
        }

      },
       async getData(){
        this.cellValues=[]
        if(this.widget.height==='fixed'){
            this.tableHeight='160px'
        }
            this.data={}
            let yesterday_date=moment().subtract(1,'days').format('YYYYMMDD')
            // console.log(yesterday_date,'yesterday_date')
            let Compare_date = null
            let Compare_date_start = null
            let Compare_date_end = null
            if(this.widget.meta.time_frame==='lw'){
                Compare_date=moment().subtract(7,'days')
                Compare_date_start=Compare_date.startOf('week').format('YYYYMMDD')
                Compare_date_end=Compare_date.endOf('week').format('YYYYMMDD')
            }else{
                Compare_date=moment()
                Compare_date_start=Compare_date.subtract(1,'months').startOf('month').format('YYYYMMDD')
                Compare_date_end=Compare_date.subtract('months').endOf('month').format('YYYYMMDD')   
            }
            // console.log(Compare_date_start,'Compare_date_start')
            // console.log(Compare_date_end,'Compare_date_end')
            if(this.widget && this.widget.meta && this.widget.meta.id){
                //console.log('widget meta data present')
                let counter=0
                for(let i of this.widget.meta.data){
                    let typeoff =typeof(i.params[0])
                    if(i.params.length>1 && typeoff==='object'){
                      this.count=0
                      for(let j of i.params){
                        if(j.length>0){
                          let payload1={
                          parameters:j,
                          from_date:yesterday_date
                        }

                    // getting yesterdays data 
                     let response=await axios.post(this.$store.state.api+'getTotalParamUsage',payload1,{headers: {
                      Authorization: 'Bearer '+ this.$store.state.jwt
                      }})
                      if(response.data.status==='success'){
                        if (this.count===0) {
                          this.positive_yesterday = Number(response.data.data['usage'])
                        }else{
                          this.negative_yesterday = Number(response.data.data['usage'])
                        }
                      this.data_temp_yesterday= this.positive_yesterday - this.negative_yesterday
                      this.count = this.count+1
                      }else{
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                      this.loading=false
                    }
                      }

                    // getting compare date data
                    this.count=0
                    for(let j of i.params){
                        if(j.length>0){
                    let payload2={
                        parameters:j,
                        from_date:Compare_date_start,
                        to_date:Compare_date_end,
                      }
                     let response2=await axios.post(this.$store.state.api+'getTotalParamUsage',payload2,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                      }})
                      if(response2.data.status==='success'){
                        if (this.count===0) {
                          this.positive_compare = Number(response2.data.data['avg'])
                        }else{
                          this.negative_compare = Number(response2.data.data['avg'])
                        }
                      this.data_temp_compare= this.positive_compare - this.negative_compare
                      this.count = this.count+1
                      }else{
                          this.info=response2.data.msg
                          this.showDismissibleAlert=true
                      }
                      this.loading=false
                    }
                    }
                    let prc=this.data_temp_compare?Math.round(((this.data_temp_compare-this.data_temp_yesterday)/this.data_temp_compare)*100):'NA'
                    let temp = {label:i.label,
                    value:isNaN(this.data_temp_yesterday)?'-':Math.round((this.data_temp_yesterday)*100)/100,
                    percentage:isNaN(prc)?'NA':prc,
                    color:this.getColor(i.color,prc)
                    }
                    
                    this.cellValues.push(temp)
                    }else{
                        let payload1={
                          parameters:i.params,
                          from_date:yesterday_date
                        }
                        let response=await axios.post(this.$store.state.api+'getTotalParamUsage',payload1,{headers: {
                      Authorization: 'Bearer '+ this.$store.state.jwt
                      }})
                      if(response.data.status==='success'){
                        this.data_temp_yesterday= Number(response.data.data['usage'])
                      }else{
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                        let payload2={
                          parameters:i.params,
                          from_date:Compare_date_start,
                         to_date:Compare_date_end,
                        }
                        let response2=await axios.post(this.$store.state.api+'getTotalParamUsage',payload2,{headers: {
                      Authorization: 'Bearer '+ this.$store.state.jwt
                      }})
                      if(response2.data.status==='success'){

                        this.data_temp_compare= Number(response2.data.data['avg'])
                      }else{
                          this.info=response.data.msg
                          this.showDismissibleAlert=true
                      }
                      this.loading=false
                    //   console.log(this.data_temp_yesterday,'this.data_temp_yesterday')
                    //   console.log(this.data_temp_compare,'this.data_temp_compare')
                      let prc=this.data_temp_compare?Math.round(((this.data_temp_compare-this.data_temp_yesterday)/this.data_temp_compare)*100):'NA'
                      let temp = {label:i.label,
                      value:isNaN(this.data_temp_yesterday)?'-':Math.round((this.data_temp_yesterday)*100)/100,
                      percentage:isNaN(prc)?'NA':prc,
                      color:this.getColor(i.color,prc)
                    }
                    this.cellValues.push(temp)
                    }
                    counter=counter+1
                }
            }
        },
        deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },
        cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }  
    },
    watch:{
        widget:{
            handler(){
                this.getData()
            },
            deep:true
            
        }
    }

}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
.percentageHighlight{
    padding:2.5px;
    border-radius:3px;
    display:inline-block;
}

</style>