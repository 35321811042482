export default {
  rules: {
    required: value => !!value || 'Required',
    validString: v => /\S+/.test(v) || 'Must be a Valid text',
    num: v => (v && !isNaN(v))|| 'It should be a valid number',
    unum: v => (v && !isNaN(v) && Number(v)>=0)|| 'It should be a valid positive number',
    noSpace:v => /^\S+$/.test(v) || 'No spaces allowed',
    integ:v => /[0-9]+$/.test(v) || 'It should be an integer',
    email:v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ip:v => (/[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+$/.test(v) && v.length >= 8 && v.length < 16 )|| 'Invalid IP',
    nonZeroLength:v => (v && v.toString().length>0) || 'Required',
    nonZeroLengthDynamic:(size)=>{ return v => (v && v.length <= size && v.toString().length > 0) || 'Length Should be between 1-'+size.toString()+' Characters' },
    size:(size)=>{
      if(isNaN(size) || Number(size)==0){
          size=15
      }
      return v => (v && v.toString().length <= size && v.toString().length > 0) || 'Must be less than '+size.toString()+' characters'
    },
  },
  objectRules:[
    v => !!v || 'Required',
],
nameRules: [
    v => !!v || 'Name is required',
    v => /\S+/.test(v) || 'Name is required',
    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 26 characters',
],
emailRules: [
    v => !!v || 'E-mail is required',
    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
],
bitRules:[
  v => !!v || 'Required',
  v => /[0-9]+$/.test(v) || 'It should be a number',
  v => (v && !isNaN(v))|| 'It should be a valid number',
  v => (v && v.length <= 2 && v.length > 0) || "It can't be more that 2 digits",
],
numberRules: [
    v => !!v || 'Required',
    v => /[0-9]+$/.test(v) || 'It should be a number',
    v => (v && !isNaN(v))|| 'It should be a valid number',
    v => (v && v.toString().length <= 13) || 'Number must be less than 13 characters',
],
textIDRules:(size)=>{
  if(isNaN(size) || Number(size)==0){
      size=15
  }
  return [
  v => !!v || 'Required',
  v => /^[A-Za-z0-9]+$/.test(v) || 'No Special characters or spaces allowed',
  v => (v && v.length <= size && v.length > 0) || 'Must be less than '+size.toString()+' characters',
]},
noSpaceTextRules:(size)=>{
  if(isNaN(size) || Number(size)==0){
      size=15
  }
  return [
  v => !!v || 'Required',
  v => /^\S+$/.test(v) || 'No spaces allowed',
  v => (v && v.length <= size && v.length > 0) || 'Must be less than '+size.toString()+' characters',
]},
topicRules:(size)=>{
  if(isNaN(size) || Number(size)==0){
      size=15
  }
  return [
  v => !!v || 'Required',
  v => /^\S+$/.test(v) || 'No spaces allowed',
  v => (v && v.length <= size && v.length > 0) || 'Must be less than '+size.toString()+' characters',
    ]
},
numberRulesDynamic:(size)=>{
  if(isNaN(size) || Number(size)==0){
      size=15
  }
  return [
    v => !!v || 'Required',
    v => /[0-9]+$/.test(v) || 'It should be a number',
    v => (v && !isNaN(v))|| 'It should be a valid number',
    v => (v && v.length <= size && v.length > 0) || 'Number must be less than '+size.toString()+'characters',
]
},
nonNegativeIntegerRules:[
    v => !!v || 'Required',
    v => /[0-9]+$/.test(v) || 'It should be a number',
    v => (v && v>0 && v%1==0)|| 'It should be a valid number',
    v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
],
listRules:[
v=> !!v || 'Required',
v => (v && v.length>0) || 'You have to select something',
],
      mobileNumberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid',
          v => (v && v.length == 10 ) || 'Phone must be 10 digits',
      ],
      
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      
      ipRules:[
        v => !!v || 'Required',
        v => /[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+$/.test(v) || 'Invalid IP',
        v => (v && v.length <= 16 && v.length > 0) || 'IP must be 16 characters',
    ],
    ISODateRules:[
      // v => !!v || 'Required',
      v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
  ]
}