import English from '@/utillities/languages/English'
// import German from '@/utillities/languages/German'
import Tamil from '@/utillities/languages/Tamil'
import Hindi from '@/utillities/languages/Hindi'
import German from '@/utillities/languages/German';
import French from '@/utillities/languages/French';
import Mandarin from '@/utillities/languages/Mandarin';
import Spanish from '@/utillities/languages/Spanish';

export default{
    langList:['english','french','german','hindi','mandarin','spanish','tamil'],
    langOptions:[
        {label:'English',value:'english'},
        {label:'Français',value:'french'},
        {label:'Deutsch',value:'german'},
        {label:'हिंदी',value:'hindi'},
        {label:'中文',value:'mandarin'},
        {label:'Española',value:'spanish'},
        {label:'தமிழ்',value:'tamil'},
    ],
    getLangTexts:(lang)=>{
        switch(lang){
            case 'english':
                return English;
            case 'tamil':
                return Tamil;
            case 'hindi':
                return Hindi;
            case 'german':
                return German;
            case 'french':
                return French;
            case 'mandarin':
                return Mandarin;
            case 'spanish':
                return Spanish;
        
            default:
                return English
            }
    },

}